import React from "react";
import ReactApexChart from 'react-apexcharts';

interface RadialGraphProps {
    data: any
    total: any
}



const RadialGraph: React.FC<RadialGraphProps> = (props) => {
    const {data} = props
    const {total} = props

    const posper = data ? Math.round((data[0] / total ) * 100) : 0
    const negper = data ? Math.round((data[1] / total ) * 100) : 0

    const MData = [posper, negper]
    const MTotal = total ? total : 0

    const chartOptions = {
        type: "radialBar",
        labels: ['Positive', 'Negative'],
        colors:['#2cab33', '#AB3B40'],
        total:{
            show: true,
            label: 'Total'
        }
    }
    return (
        <div>
            <ReactApexChart
                options={chartOptions}
                series={MData}
                total={MTotal}
                type="radialBar"
                height={250}
            />
        </div>
    )
}

export default RadialGraph;