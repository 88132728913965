const colorConfigs = {
    topbar: {
        bg: "#fff",
        color: "#000",
        bg2: "#fff"
    },
    mainBg: "#000",
    buttons: {
        bg: "linear-gradient(180deg, #0C94F1 0%, #019CFF 43%, #3376BE 100%)",
    },
    tables: {
        headBg: "#3376BE",
        mainBg: "#E7E7E7"
    }
};

export default colorConfigs;