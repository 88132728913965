import { type MRT_ColumnDef } from 'material-react-table';
import dayjs from 'dayjs';

const columnsDefault: MRT_ColumnDef<any>[] = [
    {
        accessorKey: 'id',
        header: 'Feedback ID',
        size: 50,
    },
    {
        accessorFn: (row) => dayjs(row.timestamp).format('YYYY-MM-DD HH:mm:ss'),
        header: 'Date Receieved',
        size: 50,
    },
    {
        accessorKey: 'reader_feedback',
        header: 'Reader Feedback',
        size: 50,
    },
    {
        accessorKey: 'test_feedback',
        header: 'Test Feedback',
        size: 50,
    },
    {
        accessorKey: 'app_version',
        header: 'App Version',
        size: 50,
    },
    {
        accessorKey: 'device_model',
        header: 'Device Model',
        size: 50,
    },
    {
        accessorKey: 'device_uuid',
        header: 'Device UUID',
        size: 50,
    },
    {
        accessorKey: 'lot_number',
        header: 'Lot No',
        size: 50,
    },
    {
        accessorKey: 'organisation_id',
        header: 'Organisation ID',
        size: 50,
    },
    {
        accessorKey: 'os_version',
        header: 'OS Version',
        size: 50,
    },
    {
        accessorKey: 'resuld_uuid',
        header: 'Result UUID',
        size: 50,
    },
    {
        accessorKey: 'test_type',
        header: 'Test Type',
        size: 50,
    },
    {
        accessorKey: 'user_id',
        header: 'User ID',
        size: 50,
    },
];


const columnsNovarum: MRT_ColumnDef<any>[] = [
    {
        accessorKey: 'id',
        header: 'Feedback ID',
        size: 50,
    },
    {
        accessorFn: (row) => dayjs(row.timestamp).format('YYYY-MM-DD HH:mm:ss'),
        header: 'Date Receieved',
        size: 50,
    },
    {
        accessorKey: 'reader_feedback',
        header: 'Reader Feedback',
        size: 50,
    },
    {
        accessorKey: 'test_feedback',
        header: 'Test Feedback',
        size: 50,
    },
    {
        accessorKey: 'app_version',
        header: 'App Version',
        size: 50,
    },
    {
        accessorKey: 'device_model',
        header: 'Device Model',
        size: 50,
    },
    {
        accessorKey: 'device_uuid',
        header: 'Device UUID',
        size: 50,
    },
    {
        accessorKey: 'lot_number',
        header: 'Lot No',
        size: 50,
    },
    {
        accessorKey: 'organisation_id',
        header: 'Organisation ID',
        size: 50,
    },
    {
        accessorKey: 'os_version',
        header: 'OS Version',
        size: 50,
    },
    {
        accessorKey: 'resuld_uuid',
        header: 'Result UUID',
        size: 50,
    },
    {
        accessorKey: 'test_type',
        header: 'Test Type',
        size: 50,
    },
    {
        accessorKey: 'user_id',
        header: 'User ID',
        size: 50,
    },
];


export const FeedbackColumns = {
    "99": columnsDefault,
    "1": columnsNovarum,
    "2": columnsNovarum,
    "3": columnsNovarum
}
