import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef } from 'material-react-table';
import dayjs from 'dayjs';
import { Box, Button, Grid, Link, ListItemIcon, Menu, MenuItem, MenuProps, Paper, alpha, styled } from '@mui/material';
import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CssBaseline from '@mui/material/CssBaseline';
import { UserSessionStorageKey, UserSessionStorageModel, UserTableParameterStorageService } from "../../../services/sessionStorage";

const StyledMenu = styled((props: any) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


interface UsersListProps {
    columns: any[];
    data: any[];
    isLoading: boolean;
    sorting: any;
    columnVisibility: any;
    columnFilters: any;
    pagination: any;
    globalFilter: any;
    showGlobalFilter: any;
    setShowGlobalFilter: (value: any) => void;
    handleRemoveAllFilters: () => void;
    setGlobalFilter: (value: string) => void;
    setSorting: (sorting: any[]) => void;
    setColumnVisibility: (columnVisibility: { [key: string]: boolean }) => void;
    setColumnFilters: (filters: any[]) => void;
    setPagination: (pagination: { pageSize: number; pageIndex: number }) => void;
    setUser: (detail: any) => void;
  }


const UsersList: React.FC<UsersListProps> = ({
    columns,
    data,
    isLoading,
    sorting,
    columnVisibility,
    columnFilters,
    pagination,
    globalFilter,
    showGlobalFilter,
    setShowGlobalFilter,
    setGlobalFilter,
    handleRemoveAllFilters,
    setSorting,
    setColumnVisibility,
    setColumnFilters,
    setPagination,
    setUser,
}) => {
    const navigate = useNavigate()

    
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSaveFilterClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

      const handleExportRows = (rows: MRT_Row<any>[]) => {
        
    };

    // Define the type for the keys for handling persistance of table settings in session storage
    type UserSessionStorageKeyType = keyof UserSessionStorageModel;

    // Generic handler function for persisting table parameters
    const handleParameterChange = <T,>(
        key: UserSessionStorageKeyType,
        currentValue: T,
        setValueFunction: (value: T) => void,
        newValue: T | ((prevValue: T) => T)
        ) => {
        const updatedValue = typeof newValue === 'function' ? (newValue as (prevValue: T) => T)(currentValue) : newValue;
        UserTableParameterStorageService.set(key, updatedValue);
        setValueFunction(updatedValue);
        };

    // Handlers for persisting table: sorting, pagination, visibility, filter, search, and search bar visibility
    const setSortingFunction = (newSortingValue: any) =>
        handleParameterChange(UserSessionStorageKey.sorting, sorting, setSorting, newSortingValue);
    const setPaginationChangeFunction = (newPaginationValue: any) =>
        handleParameterChange(UserSessionStorageKey.pagination, pagination, setPagination, newPaginationValue);
    const setColumnVisibilityFunction = (newColumnVisibilityValue: any) =>
        handleParameterChange(UserSessionStorageKey.columnVisibility, columnVisibility, setColumnVisibility, newColumnVisibilityValue);
    const setFilterChangeFunction = (newFilterChangeValue: any) =>
        handleParameterChange(UserSessionStorageKey.columnFilters, columnFilters, setColumnFilters, newFilterChangeValue);
    const setGlobalFilterChangeFunction = (newGlobalFilterChangeValue: string | ((prevValue: string) => string)) =>
        handleParameterChange(UserSessionStorageKey.search, globalFilter, setGlobalFilter, newGlobalFilterChangeValue);
    const setShowGlobalFilterChangeFunction = (newShowGlobalFilterChangeValue: any) =>
        handleParameterChange(UserSessionStorageKey.showSearch, showGlobalFilter, setShowGlobalFilter, newShowGlobalFilterChangeValue);


    return <MaterialReactTable
            columns={columns}
            data={data}
            state={{
                isLoading,
                sorting,
                columnVisibility,
                columnFilters,
                pagination,
                globalFilter,
                showGlobalFilter
              }}
            onSortingChange={setSortingFunction}
            onColumnVisibilityChange={setColumnVisibilityFunction}
            onColumnFiltersChange={setFilterChangeFunction}
            onPaginationChange={setPaginationChangeFunction}
            onGlobalFilterChange={setGlobalFilterChangeFunction}
            onShowGlobalFilterChange={setShowGlobalFilterChangeFunction}

            muiPaginationProps={{
            rowsPerPageOptions: [50, 500, 1000],
            }}
            positionActionsColumn="last"
            enableRowSelection
            selectAllMode="all"
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid rgba(81, 81, 81, 1)',
                    backgroundColor: "#AB3B40",
                    color: "white"
                },
            }}
            muiTableBodyRowProps={({ row }) => ({
                onClick: (event) => {
                    console.log(row.original);
                    setUser(row.original)
                },
                sx: {
                    cursor: 'pointer',
                },
            })}
            // initialState={{
            //     pagination: {
            //         pageSize: 50,
            //         pageIndex: 0
            //     },
            //     sorting: [
            //       {
            //         id: 'id',
            //         desc: true,
            //       },
            //     ],
            //     columnVisibility: {
            //         sample_id: false,
            //       },
            //     columnFilters: [
            //         {
            //             id: 'user_id',
            //             value: 1,
            //         },
            //     ],
            // }}

            renderTopToolbarCustomActions={({ table }) => {

                const handleArchiveResults = () => {
                    table.getSelectedRowModel().flatRows.map(row => {
                        //Add archive logic
                    });
                };

                const handleExportRows = (rows: MRT_Row<any>[]) => {
                    const excel_rows: string[] = [];
                    const now = dayjs().format('YYYY-MM-DD_HH-mm-ss');
                    const filename = `${now}_Users.csv`;
                  
                    // Column headers
                    excel_rows.push("ID,Email,First Name,Last Name,Username,User Type,Organisation,Date Joined,Last Login, Is Active,Is Staff,Is Superuser");
                  
                    rows.forEach(row => {
                      const rowArray = [
                        row.original.id,
                        row.original.email,
                        row.original.first_name,
                        row.original.last_name,
                        row.original.username,
                        row.original.user_type,
                        row.original.organisation,
                        row.original.date_joined ? dayjs(row.original.date_joined).format('YYYY-MM-DD HH:mm:ss') : "",
                        row.original.last_login ? dayjs(row.original.last_login).format('YYYY-MM-DD HH:mm:ss') : "",
                        row.original.is_active,
                        row.original.is_staff,
                        row.original.is_superuser,
                      ].map(field => `"${field}"`);
                      excel_rows.push(rowArray.join(','));
                    });
                  
                    // Combine all rows into a single CSV string
                    const csvContent = excel_rows.join('\n');
                  
                    // Trigger download
                    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();

                    // Check if the link has a parent node before removing it
                    if (link.parentNode) {
                        link.parentNode.removeChild(link);
                    }
                  };

                return (
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <Button
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                            sx={{ textTransform: 'none', fontSize: 16, background: '#AB3B40', color: "white", mr: "10px" }}
                        >
                            Bulk actions
                        </Button>
                        <Button
                            id="demo-customized-button1"
                            variant="contained"
                            disableElevation
                            onClick={() => handleRemoveAllFilters()}
                            sx={{ textTransform: 'none', fontSize: 16, background: '#AB3B40', color: "white" }}
                        >
                            Reset Filters
                        </Button>
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem disabled={
                                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                            } onClick={() => handleExportRows(table.getSelectedRowModel().rows)} disableRipple>
                                Export to CSV
                            </MenuItem>
                            {/* <MenuItem onClick={handleArchiveResults} disableRipple>
                                Archive Results
                            </MenuItem> */}
                        </StyledMenu>
                    </div>
                );
            }}
        />
}
 
export default UsersList;

