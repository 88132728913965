import React from "react";
import ReactApexChart from 'react-apexcharts';

interface BarGraphProps {
    data: number[]
    total: number
}

const BarGraph: React.FC<BarGraphProps> = (props) => {
    const {data} = props;
    const chartOptions = {
        type: "bar",
        colors:['#AB3B40'],
        xaxis: {
            categories: ["Reader Good", "Reader Bad", "Test Good", "Test Bad"],
            labels:{
                style:{
                    colors:'#fff'
                }
            }
        },
        series: [
            {
                name: 'Feedback',
                data: data,
            }
        ],
        
    }
    return (
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <ReactApexChart
                options={chartOptions}
                series={chartOptions.series}
                type="bar"
                height={250}
                width={300}
            />
        </div>
    )
}

export default BarGraph;