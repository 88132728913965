import { type MRT_ColumnDef } from 'material-react-table';

const columnsDefault: MRT_ColumnDef<any>[] = [
    {
        accessorKey: 'id',
        header: 'ID',
        size: 50,
    },
    {
        accessorKey: 'email',
        header: 'Email',
        size: 100,
    },
    {
        accessorKey: 'first_name',
        header: 'First Name',
        size: 100,
    },
    {
        accessorKey: 'last_name',
        header: 'Last Name',
        size: 100,
    },
    {
        accessorKey: 'username',
        header: 'Username',
        size: 100,
    },
    {
        accessorKey: 'date_joined',
        header: 'Date Joined',
        size: 100,
    },
    {
        accessorKey: 'last_login',
        header: 'Last Login',
        size: 100,
    },
    {
        accessorKey: 'is_active',
        header: 'Active',
        size: 50,
        Cell: ({ row }) => row.original.is_active ? 'Yes' : 'No'
    },
    {
        accessorKey: 'is_staff',
        header: 'Staff',
        size: 50,
        Cell: ({ row }) => row.original.is_staff ? 'Yes' : 'No'
    },
    {
        accessorKey: 'is_superuser',
        header: 'Superuser',
        size: 50,
        Cell: ({ row }) => row.original.is_superuser ? 'Yes' : 'No'
    },
    {
        accessorKey: 'organisation',
        header: 'Organisation',
        size: 50,
    },
    {
        accessorKey: 'user_type',
        header: 'User Type',
        size: 100,
    }
];



const columnsNovarum: MRT_ColumnDef<any>[] = [
    {
        accessorKey: 'id',
        header: 'ID',
        size: 50,
    },
    {
        accessorKey: 'email',
        header: 'Email',
        size: 100,
    },
    {
        accessorKey: 'first_name',
        header: 'First Name',
        size: 100,
    },
    {
        accessorKey: 'last_name',
        header: 'Last Name',
        size: 100,
    },
    {
        accessorKey: 'username',
        header: 'Username',
        size: 100,
    },
    {
        accessorKey: 'date_joined',
        header: 'Date Joined',
        size: 100,
    },
    {
        accessorKey: 'last_login',
        header: 'Last Login',
        size: 100,
    },
    {
        accessorKey: 'is_active',
        header: 'Active',
        size: 50,
        Cell: ({ row }) => row.original.is_active ? 'Yes' : 'No'
    },
    {
        accessorKey: 'is_staff',
        header: 'Staff',
        size: 50,
        Cell: ({ row }) => row.original.is_staff ? 'Yes' : 'No'
    },
    {
        accessorKey: 'is_superuser',
        header: 'Superuser',
        size: 50,
        Cell: ({ row }) => row.original.is_superuser ? 'Yes' : 'No'
    },
    {
        accessorKey: 'organisation',
        header: 'Organisation',
        size: 50,
    },
    {
        accessorKey: 'user_type',
        header: 'User Type',
        size: 100,
    }
];



export const UserColumns = {
    "99": columnsDefault,
    "1": columnsNovarum,
    "2": columnsNovarum,
    "3": columnsNovarum
}