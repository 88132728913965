import axios from "axios";
import {jwtDecode} from 'jwt-decode';


axios.defaults.baseURL = window.env.API_URL;



interface MyToken {
  exp: number;
}

//  LOGIN
export async function login(username: string, password: string) {
  const payload = new FormData();
  payload.append('username', username);
  payload.append('password', password);

  try {
      const response = await axios.post("auth/jwt-token/", payload, {
          headers: { "Content-Type": "multipart/form-data" }
      });
      if (response.data) {
          localStorage.setItem("refresh", response.data.refresh_token);
      }
      return response.data;
  } catch (error) {
      console.error("Login failed:", error);
      throw error;
  }
}

//  IS LOGGED IN
interface MyToken {
  exp: number;
  iat: number;
}

export function isLoggedIn(access: any) {
  if (access) {
    const decoded: MyToken = jwtDecode(access);
    const currentTime = new Date().getTime() / 1000; // Convert to seconds
    const lifespan = decoded.exp - decoded.iat;
    const thresholdTime = decoded.iat + (lifespan * 0.7);

    return currentTime < thresholdTime;
  }
  return false;
}

//  LOGOUT
export function logout() {
  localStorage.clear();
}

//  GET PROFILE
export async function getProfile(access: string) {
  if (access) {
    console.log("access", access)
      try {
          const response = await axios.get("api/profile/", {
              headers: { 'Authorization': `Bearer ${access}` }
          });
          const userData = response.data;
          console.log("userData", userData);
          return userData;
      } catch (error) {
          console.error("Failed to fetch profile:", error);
          throw error;
      }
  }
}

//  REFRESH TOKEN
export async function refreshToken() {
  const refresh = localStorage.getItem("refresh");
  const post_data = {
    refresh: refresh
  }
  if (refresh) {
      try {
          const response = await axios.post("auth/jwt-token/refresh/", post_data);
          if (response.data) {
              localStorage.setItem("refresh", response.data.refresh_token);
              return response.data
          }
      } catch (error) {
          console.error("Refresh failed:", error);
          throw error;
      }
  }
}
