import { Outlet } from "react-router-dom";
import { Box, styled, Toolbar } from "@mui/material";
import sizeConfigs from "../../configs/sizeConfigs";

import colorConfigs from "../../configs/colorConfigs";
import React from "react";
import Topbar from "../common/Topbar";


const MainLayout = () => {
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      m: 0,
      height: "100vh", 
      width: "100vw",
      overflowY: "auto",
      p: 0
      }}
      >
      <Topbar />
      <Box
        component="main"
        sx={{
          paddingTop: '100px',
          flexGrow: 1,
        }}
      > 
        <Outlet/>
      </Box>
    </Box>
  );
};

export default MainLayout;