
export interface iStorageService<T> {
    get<K extends keyof T>(key: K): T[K] | undefined;
  
    set<K extends keyof T>(key: K, value: T[K]): void;
  
    remove<K extends keyof T>(key: K): void;
  
    clear(): void;
  }

export class StorageService<T> implements iStorageService<T> {
    constructor(private readonly storage: Storage) {}

    set<K extends keyof T>(key: K, value: T[K]): void {
        this.storage.setItem(key.toString(), JSON.stringify(value));
    }

    get<K extends keyof T>(key: K): T[K] | undefined {
        const value = this.storage.getItem(key.toString());

        if (value === null || value === 'null'
        || value === undefined || value === 'undefined') {
        return undefined;
        }

        return JSON.parse(value);
    }
    remove<K extends keyof T>(key: K): void {
        this.storage.removeItem(key.toString());
    }

    clear(): void {
        this.storage.clear();
    }
}

// Remove Session Storage at logout
export const clearAllSessionStorage = () => {
    Object.values(ResultSessionStorageKey).forEach(key => {
      sessionStorage.removeItem(key);
    });
    Object.values(FeedbackSessionStorageKey).forEach(key => {
        sessionStorage.removeItem(key);
      });
    Object.values(UserSessionStorageKey).forEach(key => {
        sessionStorage.removeItem(key);
    });
  };


export enum ResultSessionStorageKey {
    sorting = 'result_sorting',
    columnVisibility = 'result_columnVisibility',
    pagination = 'result_pagination',
    columnFilters = 'result_columnFilters',
    search = 'result_search',
    showSearch = 'result_showSearch',
    data = 'results_data'
}

export enum FeedbackSessionStorageKey {
    sorting = 'feedback_sorting',
    columnVisibility = 'feedback_columnVisibility',
    pagination = 'feedback_pagination',
    columnFilters = 'feedback_columnFilters',
    search = 'feedback_search',
    showSearch = 'feedback_showSearch',
    data = 'feedback_data'
}

export enum UserSessionStorageKey {
    sorting = 'user_sorting',
    columnVisibility = 'user_columnVisibility',
    pagination = 'user_pagination',
    columnFilters = 'user_columnFilters',
    search = 'user_search',
    showSearch = 'user_showSearch',
    data = 'user_data'
}

export interface ResultSessionStorageModel {
    [ResultSessionStorageKey.sorting]: any;
    [ResultSessionStorageKey.columnVisibility]: any;
    [ResultSessionStorageKey.pagination]: any;
    [ResultSessionStorageKey.columnFilters]: any;
    [ResultSessionStorageKey.search]: any;
    [ResultSessionStorageKey.showSearch]: any;
    [ResultSessionStorageKey.data]: any;
  }

export interface FeedbackSessionStorageModel {
    [FeedbackSessionStorageKey.sorting]: any;
    [FeedbackSessionStorageKey.columnVisibility]: any;
    [FeedbackSessionStorageKey.pagination]: any;
    [FeedbackSessionStorageKey.columnFilters]: any;
    [FeedbackSessionStorageKey.search]: any;
    [FeedbackSessionStorageKey.showSearch]: any;
    [FeedbackSessionStorageKey.data]: any;
}

export interface UserSessionStorageModel {
    [UserSessionStorageKey.sorting]: any;
    [UserSessionStorageKey.columnVisibility]: any;
    [UserSessionStorageKey.pagination]: any;
    [UserSessionStorageKey.columnFilters]: any;
    [UserSessionStorageKey.search]: any;
    [UserSessionStorageKey.showSearch]: any;
    [UserSessionStorageKey.data]: any;
}

export const ResultTableParameterStorageService = new StorageService<ResultSessionStorageModel>(
    sessionStorage
);

export const FeedbackTableParameterStorageService = new StorageService<FeedbackSessionStorageModel>(
    sessionStorage
);
  
export const UserTableParameterStorageService = new StorageService<UserSessionStorageModel>(
    sessionStorage
);