import { Box, Button, Grid, Link, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { isLoggedIn, refreshToken } from '../../redux/features/auth/authService';
import ResultsList from '../../components/common/tables/ResultsList';
import SignInDialog from '../../components/common/SignInDialog';
import axios from 'axios';
import ResultsFilterBar from '../../components/common/cards/ResultsFilterBar';
import { organisationColumns } from '../../components/common/tables/Columns';
import { logout, refreshAccessToken } from '../../redux/features/auth/authSlice';
import { FeedbackColumns } from '../../components/common/tables/FeedbackColumns';
import FeedbackList from '../../components/common/tables/FeedbackList';
import EditFeedbackCard from '../../components/common/cards/EditFeedbackCard';
import FeedbackFilterBar from '../../components/common/cards/FeedbackFilterBar';
import { FeedbackSessionStorageKey, FeedbackTableParameterStorageService } from '../../services/sessionStorage';

const loginpage = "/login"

axios.defaults.baseURL = window.env.API_URL;


type Props = {}

const FeedbackPage = (props: Props) => {
  
const navigate = useNavigate()
const authData = useSelector((state: RootState) => state.auth);
const [columns, setColumns] = useState(FeedbackColumns["99"]);
const [isLoading, setIsLoading] = useState(false);
const [feedback, setFeedback] = useState<any>(null);
const [savedFilterSets, setSavedFilterSets] = useState<any[]>([]);


const [sorting, setSorting] = useState<any>(() => {
  return FeedbackTableParameterStorageService.get(FeedbackSessionStorageKey.sorting) || []
});

const [columnVisibility, setColumnVisibility] = useState<any>(() => {
  return FeedbackTableParameterStorageService.get(FeedbackSessionStorageKey.columnVisibility) || {}
});

const [columnFilters, setColumnFilters] = useState<any>(() => {
  return FeedbackTableParameterStorageService.get(FeedbackSessionStorageKey.columnFilters) || []
});

const [globalFilter, setGlobalFilter] = useState<string>(() => {
  return FeedbackTableParameterStorageService.get(FeedbackSessionStorageKey.search) || "";
});

const [pagination, setPagination] = useState<any>(() => {
  return FeedbackTableParameterStorageService.get(FeedbackSessionStorageKey.pagination) || { pageSize: 1000, pageIndex: 0 }
});

const [showGlobalFilter, setShowGlobalFilter] = useState<any>(() => {
  return FeedbackTableParameterStorageService.get(FeedbackSessionStorageKey.showSearch) || false
});

const [data, setData] = useState<any>(() => {
  return FeedbackTableParameterStorageService.get(FeedbackSessionStorageKey.data) || []
});

const handleRemoveAllFilters = () => {
  setGlobalFilter("")
  setShowGlobalFilter(false)
  setColumnFilters([])
  setColumnVisibility({})
  setPagination({ pageSize: 1000, pageIndex: 0 })
  setSorting([])
}


// Check if filters being saved
useEffect(() => {
  console.log("sorting", sorting)
  console.log("columnVisibility", columnVisibility)
  console.log("columnFilters", columnFilters)
}, [sorting, columnVisibility, columnFilters])

// AUTHENTICATION CHECK
const dispatch = useDispatch<AppDispatch>();
useEffect(() => {
  if (!authData.isAuthenticated) {
      dispatch(logout());
      navigate('/login');
      return;
  }
  if (!isLoggedIn(authData.access)) {
      dispatch(refreshAccessToken());
  }
  dispatch(refreshAccessToken());
}, [dispatch, navigate, authData.isAuthenticated, authData.access]);

  return authData.isAuthenticated == false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
    ) : authData.isAuthenticated == true ? (
  <div style={{ height: "100%", overflowX: "auto"}}>
    <div style={{ height: '100%', display: "flex", alignItems: "flex-start", }}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
          style={{ height: '100%' }}
        >
          <Grid item xs={12}>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
              <FeedbackFilterBar setData={setData} setIsLoading={setIsLoading} handleRemoveAllFilters={handleRemoveAllFilters} />
              {/* {feedback && <EditFeedbackCard feedback={feedback} setFeedback={setFeedback}/>}
              {!feedback && ( */}
                <Paper variant="outlined" sx={{ width: '95vw', overflow: 'auto', mx: "auto", padding: 2, boxShadow: 2, mt: 5, ml: 3 }}>
                <FeedbackList
                    columns={columns}
                    data={data}
                    isLoading={isLoading}
                    sorting={sorting}
                    columnVisibility={columnVisibility}
                    columnFilters={columnFilters}
                    pagination={pagination}
                    globalFilter={globalFilter}
                    showGlobalFilter= {showGlobalFilter}
                    setShowGlobalFilter={setShowGlobalFilter}
                    handleRemoveAllFilters={handleRemoveAllFilters}
                    setGlobalFilter={setGlobalFilter}
                    setSorting={setSorting}
                    setColumnVisibility={setColumnVisibility}
                    setColumnFilters={setColumnFilters}
                    setPagination={setPagination}
                    setFeedback={setFeedback}
                  />
                </Paper>
              {/* )}             */}
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
) : (null);
};

export default FeedbackPage;

