import React from 'react';
import { FieldProps, getIn } from 'formik';
import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';



export const FormDateFromPicker: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
    
    const { error, helperText, field, form, ...rest } = props
    
      return (

          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
          <DatePicker
              label="From"
              value={dayjs(field.value)}
              sx={{
                margin: '20px',
                '& .MuiInputBase-root': {
                  fontSize: '0.9rem',
                }}}
              onChange={(newValue) => {
                  if (newValue) {
                    form.setFieldValue(field.name, newValue ? dayjs(newValue).toISOString() : "");
                  }
              }}
          />
          </DemoContainer>
          </LocalizationProvider>
      )
    }

export const FormDateToPicker: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
        <DatePicker
            label="To"
            value={dayjs(field.value)}
            sx={{
              margin: '20px',
              '& .MuiInputBase-root': {
                fontSize: '0.9rem',
              }}}
            onChange={(newValue) => {
                if (newValue) {
                  form.setFieldValue(field.name, newValue ? dayjs(newValue).toISOString() : "");
                }
            }}
        />
        </DemoContainer>
        </LocalizationProvider>
    )
  }

  export const FormFeedbackIdTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        label="Feedback ID"
        margin="normal"
        placeholder='Enter full ID'
        autoFocus
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}  
      />
    )
  }

  export const FormUserIdTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        label="User ID"
        margin="normal"
        placeholder='User identifier entered in app'
        autoFocus
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}
      />
    )
  }

/**
 * Material TextField Component with Formik Support including Errors.
 * Intended to be specified via the `component` prop in a Formik <Field> or <FastField> component.
 * Material-UI specific props are passed through.
 */
export const FormTestTypeTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        label="Test Type"
        margin="normal"
        autoFocus
        placeholder='e.g. Pheonix...'
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}
      />
    )
  }

  interface FormTestTypeSelectFieldProps extends FieldProps {
    options: { value: string | number, label: string }[];
  }

  //  Buhlmann Contact Select
  export const FormTestTypeSelectField: React.FC<FormTestTypeSelectFieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, options, type, ...rest } = props
  
    return (
      <TextField
        error={Boolean(isTouched && errorMessage)}
        helperText={(isTouched && errorMessage) ? errorMessage : undefined}
        {...rest}
        {...field}
        fullWidth
        select
        label="Test Types"
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}
      >
           <MenuItem key={0} value={""}>
              <em>&nbsp;</em>
           </MenuItem>
  
        {options.map((option, index) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      
      </TextField>
    )
  }

  interface FormOrganisationSelectFieldProps extends FieldProps {
    options: { value: string | number, label: string }[];
  }

  //  Buhlmann Contact Select
  export const FormOrganisationSelectField: React.FC<FormOrganisationSelectFieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, options, type, ...rest } = props
  
    return (
      <TextField
        error={Boolean(isTouched && errorMessage)}
        helperText={(isTouched && errorMessage) ? errorMessage : undefined}
        {...rest}
        {...field}
        fullWidth
        select
        label="Organisation"
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}
      >
           <MenuItem key={0} value={""}>
              <em>&nbsp;</em>
           </MenuItem>
  
        {options.map((option, index) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      
      </TextField>
    )
  }


  interface FormTestFeedbackSelectFieldProps extends FieldProps {
    options: { value: string | number, label: string }[];
  }

  //  Buhlmann Contact Select
  export const FormTestFeedbackSelectField: React.FC<FormTestFeedbackSelectFieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, options, type, ...rest } = props
  
    return (
      <TextField
        error={Boolean(isTouched && errorMessage)}
        helperText={(isTouched && errorMessage) ? errorMessage : undefined}
        {...rest}
        {...field}
        fullWidth
        select
        label="Test Feeback"
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}
      >
           <MenuItem key={0} value={""}>
              <em>&nbsp;</em>
           </MenuItem>
  
        {options.map((option, index) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      
      </TextField>
    )
  }

  interface FormTestFeedbackSelectFieldProps extends FieldProps {
    options: { value: string | number, label: string }[];
  }

  //  Buhlmann Contact Select
  export const FormReaderFeedbackSelectField: React.FC<FormTestFeedbackSelectFieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, options, type, ...rest } = props
  
    return (
      <TextField
        error={Boolean(isTouched && errorMessage)}
        helperText={(isTouched && errorMessage) ? errorMessage : undefined}
        {...rest}
        {...field}
        fullWidth
        select
        label="Reader Feedback"
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}
      >
           <MenuItem key={0} value={""}>
              <em>&nbsp;</em>
           </MenuItem>
  
        {options.map((option, index) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      
      </TextField>
    )
  }
  
/**
 * Material TextField Component with Formik Support including Errors.
 * Intended to be specified via the `component` prop in a Formik <Field> or <FastField> component.
 * Material-UI specific props are passed through.
 */
export const FormLotNumberTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        label="Lot Number"
        margin="normal"
        placeholder='e.g. lot_01'
        autoFocus
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}
      />
    )
  }


    /**
     * Material TextField Component with Formik Support including Errors.
     * Intended to be specified via the `component` prop in a Formik <Field> or <FastField> component.
     * Material-UI specific props are passed through.
     */
    export const FormDeviceModelTextField: React.FC<FieldProps & TextFieldProps> = props => {
        const isTouched = getIn(props.form.touched, props.field.name)
        const errorMessage = getIn(props.form.errors, props.field.name)
    
        const { error, helperText, field, form, ...rest } = props
    
        return (
        <TextField
        //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Device Model"
            margin="normal"
            placeholder='e.g. Pixel 7'
            autoFocus
            InputLabelProps={{
              sx: {
                fontSize: '0.9rem',
              },
            }}
            sx={{
              margin: '7px 0',
              '& .MuiInputBase-root': {
                fontSize: '0.9rem',
              },
            }}
        />
        )
    }

  export const FormDeviceUUIDTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        label="Device UUID"
        margin="normal"
        placeholder='e.g. 2c0bf4b4-9159-4258-9bdb-1c5c71dd6ad2'
        autoFocus
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}
      />
    )
  }

  export const FormOSVersionTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        label="OS Version"
        margin="normal"
        placeholder='e.g. 14'
        autoFocus
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}
      />
    )
  }

  export const FormAppVersionTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        label="App Version"
        margin="normal"
        placeholder='e.g. 1.0.0'
        autoFocus
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}
      />
    )
  }

  export const FormAppBuildTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        label="App Build"
        margin="normal"
        placeholder='e.g. 1'
        autoFocus
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}
      />
    )
  }

  export const FormSkipTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        label="Skip"
        margin="normal"
        placeholder='e.g. 50 (Skip first 50 items)'
        autoFocus
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}
      />
    )
  }

  export const FormLimitTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        label="Limit"
        margin="normal"
        placeholder='e.g. 100 (Only fetch 100 items)'
        autoFocus
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}
      />
    )
  }

  export const FormAppliedFiltersTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        label="Enter new filter name"
        margin="none"
        placeholder='e.g. My new filter'
        autoFocus
        autoComplete="off"

        InputProps={{
          sx: {
              height: '32px',
              padding: '0 14px',
              'input': {
                  height: '1.1876em',
                  padding: '5px 0'
              }
          },
        }}
        sx={{
            margin: '9.8px 0',
            height: '35px',
            '& .MuiInputBase-root': {
                height: '100%',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid',
            },
        }}
        InputLabelProps={{
          sx: {
            top: '-7px'
          }
        }}
        variant="outlined"
      />
    )
  }