import { useMemo } from "react";
import { type MRT_ColumnDef } from 'material-react-table';
import dayjs from 'dayjs';

const columnsDefault: MRT_ColumnDef<any>[] = [
        {
        accessorKey: 'id',
        header: 'Result ID',
        size: 50,
        },
        {
        accessorFn: (row) => dayjs(row.timestamp).format('YYYY-MM-DD HH:mm:ss'),
        header: 'Result Date',
        size: 50,
        },
        {
        accessorKey: 'user_id',
        header: 'User ID',
        size: 50,
        },
        {
        accessorKey: 'organisation_id',
        header: 'Organisation ID',
        size: 50,
        },
        {
        accessorKey: 'test_type',
        header: 'Test Type',
        size: 50,
        },
        {
        accessorKey: 'lot_number',
        header: 'Lot No',
        size: 50,
        },
        {
        accessorKey: 'sample_id',
        header: 'Sample ID',
        size: 50,
        },
        {
        accessorKey: 'sample_type',
        header: 'Sample Type',
        size: 50,
        },
        {
        accessorKey: 'visual_result',
        header: 'Visual Result',
        size: 50,
        },
    ]

const columnsDDTD: MRT_ColumnDef<any>[] = [
        {
        accessorKey: 'id',
        header: 'Result ID',
        size: 45,
        },
        {
        accessorFn: (row) => dayjs(row.timestamp).format('YYYY-MM-DD HH:mm:ss'),
        header: 'Result Date',
        size: 45,
        },
        {
        accessorKey: 'test_type',
        header: 'Test Type',
        size: 45,
        },
        {
        accessorKey: 'lot_number',
        header: 'Lot No',
        size: 45,
        },
        {
        accessorKey: 'sample_id',
        header: 'Sample ID',
        size: 45,
        },
        {
        accessorKey: 'sample_type',
        header: 'Sample Type',
        size: 45,
        },
        {
        accessorKey: 'teststrips.lines.tc_ratio', // accessorFn
        header: 'TC Ratio',
        size: 45,
        },
        {
        accessorKey: 'teststrips.lines.peakposition', //accessorFn
        header:'T Peak',
        size: 45,
        },
        {
        accessorKey: 'teststrips.c_line.peakposition', //accessorFn
        header: 'C Peak',
        size: 45,
        },
        {
        accessorKey: 'visual_result',
        header: 'Visual Result',
        size: 45,
        },
    ]

const columsOrg2: MRT_ColumnDef<any>[] = [
        {
        accessorKey: 'id',
        header: 'Result ID',
        size: 50,
        },
        {
        accessorFn: (row) => dayjs(row.timestamp).format('YYYY-MM-DD HH:mm:ss'),
        header: 'Result Date',
        size: 50,
        },
    ]

const columsOrg3: MRT_ColumnDef<any>[] = [
        {
        accessorKey: 'id',
        header: 'Result ID',
        size: 50,
        },
        {
        accessorKey: 'test_type',
        header: 'Test Type',
        size: 50,
        },
        {
        accessorKey: 'lot_number',
        header: 'Lot No',
        size: 50,
        },
    ]

const columnsLastFiveR: MRT_ColumnDef<any>[] = [
    {
    accessorFn: (row) => dayjs(row.timestamp).format('YYYY-MM-DD HH:mm:ss'),
    header: 'Result Date',
    size: 50,
    },
    {
    accessorKey: 'visual_result',
    header: 'Visual Result',
    size: 50,
    },
]

const columnsLastFiveF: MRT_ColumnDef<any>[] = [
    {
        accessorKey: 'reader_feedback',
        header: 'Reader Feedback',
        size: 50,
    },
    {
        accessorKey: 'test_feedback',
        header: 'Test Feedback',
        size: 50,
    },    
]


export const organisationColumns = {
    "99": columnsDefault,
    "1": columnsDDTD,
    "2": columsOrg2,
    "3": columsOrg3
}

export const last5columns = {
    "R": columnsLastFiveR,
    "F": columnsLastFiveF
}