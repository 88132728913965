import { Card, Box, Button, Paper, Typography, Alert, AlertTitle, Divider, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from 'formik';
import dayjs from 'dayjs';
import axios from 'axios';
import { fetchUserProfile, refreshAccessToken } from '../../../redux/features/auth/authSlice';
import {   
  FormDateToPicker,
  FormDateFromPicker,
  FormUserIdTextField,
  FormResultIdTextField,
  FormLotNumberTextField,
  FormTestTypeSelectField,
  FormSampleTypeTextField,
  FormSampleIDTextField,
  FormAppliedFiltersTextField,
  FormVisualResultTextField,
  FormOrganisationSelectField,
  FormLimitTextField,
  FormSkipTextField, } from '../fields/filterBarFeilds';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useOrganisations } from '../CustomHooks';
import { ResultSessionStorageKey, ResultTableParameterStorageService } from '../../../services/sessionStorage';

interface FilterSettings {
    sorting: any[];
    columnFilters: any[];
    columnVisibility: { [key: string]: boolean };
    pagination: { pageSize: number; pageIndex: number };
  }
  
interface FilterBarProps {
  setData: (data: any[]) => void;
  setIsLoading: (data: boolean) => void;
  handleRemoveAllFilters: () => void;
  }



const ResultsFilterBar: React.FC<FilterBarProps> = ({setData, setIsLoading, handleRemoveAllFilters   }) => {

    const authData = useSelector((state: RootState) => state.auth);
    const [errors, setErrors] = useState<string | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const [expanded, setExpanded] = useState<string | false>(false);
    const [saveCustomFilter, setSaveCustomFilter] = useState<boolean>(false);
    const errorRef = useRef<HTMLDivElement>(null);
    const searchRef = useRef<HTMLDivElement>(null);

    const validationSchema = () => {
        return Yup.object().shape({
          new_filter: Yup.string().max(15, 'Custom filter name must be 15 characters or less')
        });
    }
    useEffect(() => {
      if (errors && errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
  }, [errors]);
  
    const formik = useFormik({
      validateOnChange: false,
      validateOnBlur: false,
      initialValues: {
        id: "",
        user_id: "",
        from_date: dayjs().format(),
        to_date: dayjs().format(),
        test_type: "",
        lot_number: "",
        sample_id: "",
        sample_type: "",
        visual_result: "",
        new_filter: "",
        organisation_id: "",
        skip: "",
        limit: "",
        result_filters: [],
      },

      validationSchema,
      onSubmit: async (values) => {
        setErrors(null);
        setIsLoading(true)

        // Form handles getting results and patching users custom filters
        let updatedFilters;
        if (values.new_filter && authData.user) {
          const new_filter = {
            name: values.new_filter,
            filters: {
              id: values.id,
              user_id: values.user_id,
              from_date: values.from_date ? dayjs(values.from_date).format('YYYY-MM-DD') : undefined,
              to_date: values.to_date ? dayjs(values.to_date).format('YYYY-MM-DD') : undefined,
              test_type: values.test_type,
              lot_number: values.lot_number, 
              sample_id: values.sample_id, 
              sample_type: values.sample_type, 
              visual_result: values.visual_result,
              skip: values.skip,
              limit: values.limit,
              organisation_id: values.organisation_id, 
            }
          }
          updatedFilters = [new_filter, ...authData?.user?.result_filters]
          if (values.new_filter === "delete_all_custom_filters") {
            updatedFilters = []
          }
        }

        // Handle either updating users custom filters or queriying results
        const data = formik.values.new_filter ? {
            result_filters: updatedFilters
          } : {
            id: values.id,
            user_id: values.user_id,
            from_date: values.from_date ? dayjs(values.from_date).format('YYYY-MM-DD') : undefined,
            to_date: values.to_date ? dayjs(values.to_date).format('YYYY-MM-DD') : undefined,
            test_type: values.test_type,
            lot_number: values.lot_number, 
            sample_id: values.sample_id, 
            sample_type: values.sample_type, 
            visual_result: values.visual_result, 
            skip: values.skip,
            limit: values.limit,
            organisation_id: values.organisation_id,
          }
      
        // Concatinates search query parameters - Filter out empty values
        const queryParams = Object.entries(data).reduce((acc, [key, value]) => {
          if (value) { // This will ignore undefined values, effectively filtering out empty fields
            acc.set(key, value);
          }
          return acc;
        }, new URLSearchParams());
      
        // Generate the query string by concatinating any fields that have been entered
        const queryString = queryParams.toString();
      
        // Construct the URL
        let url = queryString ? `api/results/?${queryString}` : "api/results/";

        // Handles either patching users custom filters or fetching results
        const method = formik.values.new_filter ? 'patch' : 'get'
        console.log("url", url)

        // Handle updating users custom filters
        if (formik.values.new_filter) {
          url = `api/profile/`
        }

        try {
            await axios({
                method,
                url,
                data,
                headers: {
                    'Authorization': `Bearer ${authData.access}`
                }, 
            })
            .then(res => {
              handleRemoveAllFilters()
              setErrors(null);
              setData(res.data)
              setIsLoading(false)
              //set in local storage for persitance
              ResultTableParameterStorageService.set(ResultSessionStorageKey.data, res.data)
              console.log(res.data)
              console.log(`Results data successfully fetched.`)
              if (searchRef.current) {
                searchRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
              }
              if (method === 'get'){
                if (res.data.length < 1) {
                  setErrors("There are no Results matching your search.");
                } else {
                  setExpanded(false)
                }
              } else {
                dispatch(fetchUserProfile());
                formik.setFieldValue('new_filter', "")
              }
              dispatch(refreshAccessToken());
            })

        } catch(error: any) {
            setIsLoading(false)
            dispatch(refreshAccessToken());
            if (error?.response?.status === 400) {
                const firstErrorItem = Object.keys(error.response.data)[0];
                setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
            } else {
                const errorText = method === 'patch' ? `Unknown error updated your filters` : `Unknown error fetching result data`;
                setErrors(errorText);
                console.error(errorText, error);
            }
        }
      },
  });

  // Currently hardcoding test types
  const test_types = [{name: "Phoenix"}]
  const [organisations] = useOrganisations(authData)
  
  // Handles opening filter Accordian
  const handleOpeningAccordian =
  (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    setErrors(null)
  };

  // Handles setting custom filters
  const handleCustomFilter = (result_filter: any) => {
    formik.setFieldValue('id', result_filter.filters.id || "");
    formik.setFieldValue('user_id', result_filter.filters.user_id || "");
    formik.setFieldValue('from_date', result_filter.filters.from_date || "");
    formik.setFieldValue('to_date', result_filter.filters.to_date || "");
    formik.setFieldValue('test_type', result_filter.filters.test_type || "");
    formik.setFieldValue('lot_number', result_filter.filters.lot_number || "");
    formik.setFieldValue('sample_id', result_filter.filters.sample_id || "");
    formik.setFieldValue('sample_type', result_filter.filters.sample_type || "");
    formik.setFieldValue('visual_result', result_filter.filters.visual_result || "");
    formik.setFieldValue('organisation_id', result_filter.filters.organisation_id || "");
    formik.setFieldValue('skip', result_filter.filters.skip || "");
    formik.setFieldValue('limit', result_filter.filters.limit || "");
  }

  // Handles setting custom filter buttons CSS
  const doFilterValuesMatch = (formikValues: any, filter: any) => {
    if (!filter) return false;
    const keysToCheck = Object.keys(filter);
    return keysToCheck.every(key => formikValues[key] === filter[key]);
  }

  // Handles the Clear button resetting filters
  const handleResetValues = () => {
    formik.setFieldValue('id', "");
    formik.setFieldValue('user_id', "");
    formik.setFieldValue('from_date', "");
    formik.setFieldValue('to_date', "");
    formik.setFieldValue('test_type', "");
    formik.setFieldValue('lot_number', "");
    formik.setFieldValue('sample_id', "");
    formik.setFieldValue('sample_type', "");
    formik.setFieldValue('visual_result', "");
    formik.setFieldValue('skip', "");
    formik.setFieldValue('limit', "");
    formik.setFieldValue('organisation_id', "");
  }

  // Handles deleting all users custom filters
  const handleDeleteCustomFilters = () => {
    formik.setFieldValue('new_filter', "delete_all_custom_filters")
    formik.submitForm();
  }

  const handleCustomFilterSave = () => {
    setSaveCustomFilter(false)
    formik.submitForm();
  }
  
return (
  <Box ref={searchRef} sx={{ p: 1, marginX: "auto" }}>
    <FormikProvider value={formik}>
    <Paper variant="outlined" sx={{width: expanded ? '100%' : "40%", overflow: 'auto', m: "auto", p: 2, transition: "width 0.3s ease-in-out" }}>
        <form onSubmit={formik.handleSubmit}>
        <Card sx={{display:'flex', flexDirection: "column", width: "100%", justifyContent: 'space-between', boxShadow: 10, paddingBottom:"10%", borderRadius: 1, p: expanded ? 2 : 0, paddingTop: 0, overflowY: "auto", transition: "padding 0.5s ease-in-out"}} >
          {errors && (
            <Alert ref={errorRef} severity="error" style={{ marginTop: '16px' }}>
              <AlertTitle>Error</AlertTitle>
              {errors}
            </Alert>
          )}
            <div>
              <div>
                <Accordion expanded={expanded === 'panel1'} onChange={handleOpeningAccordian('panel1')}>
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 1, m: 0, ml: expanded ? "5%": 0, gap: "20px", transition: "margin-left 0.3s ease-in-out" }}
                    >
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        fontWeight: "bold",
                        m: "auto",
                        mt: 0,
                        mb: 0,
                        background: "#AB3B40",
                        borderRadius: 1,
                        width: expanded ? "65%" : "100%",
                        padding: 1,
                        transition: "width 0.3s ease-in-out"
                      }}
                    >
                      Load Results
                    </Typography>                  
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      overflowY: 'auto',
                      p: 0,
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: 2
                    }}
                    >

                    <Box sx={{ p: 1, display: "flex", gap: "30px", height: "100%"}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "30px", height: "auto", flexGrow: 1 }}>
                      <Card sx={{display:'flex', width: "100%", justifyContent: 'flex-start', boxShadow: 10, paddingBottom: "20%", borderRadius: 1, p: 1}}>

                      <div style={{display: "flex", flexDirection: "column"}}>
                      <Typography sx={{ fontWeight: "bold", p: 1}}>Date Range</Typography>
                        <div className="form-group" style={{display: "flex", alignItems: "baseline", paddingLeft: "20px"}}>
                          <Field name="from_date" type="date" className="form-control" component={FormDateFromPicker} />
                          <div className="form-group" style={{padding: "8px"}}>
                            <Button
                                onClick={(() => {formik.setFieldValue('from_date', null)})}
                                variant="outlined"
                                sx={{fontSize: "0.8rem", height: "17px", minWidth: "16px", p: 0.5, borderColor: "#AB3B40", color: "#AB3B40"}}
                                >
                                x
                            </Button>  
                          </div>
                        </div>
                        <div className="form-group" style={{display: "flex", alignItems: "baseline", paddingLeft: "20px", marginTop: "7px" }}>
                          <Field name="to_date" type="date" className="form-control" component={FormDateToPicker} />
                          <div className="form-group" style={{padding: "8px"}}>
                            <Button
                                onClick={(() => {formik.setFieldValue('to_date', null)})}
                                variant="outlined"
                                sx={{fontSize: "0.8rem", height: "17px", minWidth: "16px", p: 0.5, borderColor: "#AB3B40", color: "#AB3B40"}}
                                >
                                x
                            </Button>  
                          </div>
                        </div>
                      </div>
                      <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end", gap: "10px", paddingLeft: "20px", paddingRight: "20px", paddingTop: "48px", paddingBottom: "10px"}}>
                          <Button
                              className="form-group"
                              sx={{fontSize: "0.8rem",  width: "165px"}}
                              variant={(formik.values.from_date === dayjs().format() && formik.values.to_date === dayjs().format() ) ? "contained" : "outlined"}
                              onClick={() => {
                                formik.setFieldValue('from_date', dayjs().format());
                                formik.setFieldValue('to_date', dayjs().format());
                              }}
                              >
                              Today
                          </Button>   
                          <Button
                              className="form-group"
                              sx={{fontSize: "0.8rem",  width: "165px"}}
                              variant={(formik.values.from_date === dayjs().subtract(1, 'day').format() && formik.values.to_date === dayjs().subtract(1, 'day').format() ) ? "contained" : "outlined"}
                              onClick={() => {
                                formik.setFieldValue('from_date', dayjs().subtract(1, 'day').format());
                                formik.setFieldValue('to_date', dayjs().subtract(1, 'day').format());
                              }}
                              >
                              Yesterday
                          </Button>              
                          <Button
                              className="form-group"
                              sx={{fontSize: "0.8rem",  width: "165px"}}
                              variant={(formik.values.from_date === dayjs().subtract(1, 'week').format() && formik.values.to_date === dayjs().format() ) ? "contained" : "outlined"}
                              onClick={() => {
                                formik.setFieldValue('from_date', dayjs().subtract(1, 'week').format());
                                formik.setFieldValue('to_date', dayjs().format());
                              }}
                              >
                              Last 7 Days
                          </Button>  
                          <Button
                              className="form-group"
                              sx={{fontSize: "0.8rem",  width: "165px"}}
                              variant={(formik.values.from_date === dayjs().subtract(1, 'month').format() && formik.values.to_date === dayjs().format() ) ? "contained" : "outlined"}
                              onClick={() => {
                                formik.setFieldValue('from_date', dayjs().subtract(1, 'month').format());
                                formik.setFieldValue('to_date', dayjs().format());
                              }}
                              >
                              Month to Date
                          </Button>              
                          <Button
                              className="form-group"
                              sx={{fontSize: "0.8rem",  width: "165px"}}
                              variant={(formik.values.from_date === dayjs().subtract(3, 'month').format() && formik.values.to_date === dayjs().format() ) ? "contained" : "outlined"}
                              onClick={() => {
                                formik.setFieldValue('from_date', dayjs().subtract(3, 'month').format());
                                formik.setFieldValue('to_date', dayjs().format());
                              }}
                              >
                              Quarter to Date
                          </Button> 
                          <Button
                              className="form-group"
                              sx={{fontSize: "0.8rem",  width: "165px"}}
                              variant={(formik.values.from_date === dayjs().subtract(1, 'year').format() && formik.values.to_date === dayjs().format() ) ? "contained" : "outlined"}
                              onClick={() => {
                                formik.setFieldValue('from_date', dayjs().subtract(1, 'year').format());
                                formik.setFieldValue('to_date', dayjs().format());
                              }}
                              >
                              Year to Date
                          </Button> 
                          </div>


                      </Card>

                      <Card sx={{display: "flex", flexDirection: "column", justifyContent:"space-between",  width: "100%", boxShadow: 10, minHeight: "138px", borderRadius: 1, p:1, height: "auto" }} >   
                      <Typography sx={{ fontWeight: "bold", p: 1}}>Custom Filters</Typography>
                      <div style={{display: "flex", justifyContent: "space-between", paddingBottom: "10px"}}>

                      
                      <div style={{display: "flex", flexDirection: "column", paddingLeft: "20px", paddingRight: "20px", width: "50%"}}>
                      <div style={{display: "flex"}}>
                        {!saveCustomFilter ? 
                            <Button
                                className="form-group"
                                variant="outlined"
                                sx={{fontSize: "0.8rem", width: "200px", m: "7px 0"}}
                                onClick={() => {setSaveCustomFilter(true); formik.setFieldValue('new_filter', "")}}
                                >
                                Create Custom Filter
                            </Button>
                        : 
                            <Field name="new_filter" type="text" className="form-control" component={FormAppliedFiltersTextField} />
                          }   
                        </div>
                        {!saveCustomFilter && <Divider orientation="vertical" variant="middle" flexItem /> }


                   {!saveCustomFilter ? 
                      <Button
                          variant="outlined"
                          onClick={() => handleDeleteCustomFilters()}
                          type="button"
                          sx={{fontSize: "0.8rem", borderColor: "#AB3B40", color: "#AB3B40" }}
                          disabled={authData?.user?.result_filters?.length === 0}
                          >
                          Clear Custom Filters
                      </Button>  
                   :
                   <div style={{display: "flex", justifyContent: "space-between", marginTop: "10px"}}>
                       <Button
                         onClick={() => {setSaveCustomFilter(false); formik.setFieldValue('new_filter', "")} }
                         variant="outlined"
                         sx={{fontSize: "0.8rem"}}
                         >
                         Cancel
                       </Button> 
                       <Divider orientation="vertical" variant="middle" flexItem /> 
                       <div style={{display: "flex", gap: "30px"}}>
                         <Button
                             className="form-group"
                             variant="contained"
                             type="button"
                             sx={{fontSize: "0.8rem"}}
                             onClick={() => handleCustomFilterSave()}
                             disabled={!formik.values.new_filter}
                             autoFocus={!formik.values.new_filter}
                             >
                             Save
                         </Button> 
                       </div>  
                       </div>
                     } 
                    </div>
                    <div>   
                          {authData.user?.result_filters?.length === 0 ? null :
                          <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end", gap: "10px", paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "10px"}}>
                          {authData.user?.result_filters?.map((result_filter) => {
                            const isMatch = doFilterValuesMatch(formik.values, result_filter.filters);
                            return (
                              <Button
                                  variant={isMatch ? "contained" : "outlined"}
                                  onClick={() => {handleCustomFilter(result_filter)}}
                                  sx={{fontSize: "0.8rem",  width: "165px"}}
                                  >
                                  {result_filter.name}
                              </Button> 
                            ) 
                          })}     
                          </div> 
                          }
                          </div>  
                    </div>
                   </Card>

                   </div>

                   <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between",height: "auto", flexGrow: 1 }}>

                   <Card sx={{ display: 'flex', flexDirection: "column", justifyContent: 'flex-start', width: "100%", boxShadow: 10, paddingBottom: "20%", borderRadius: 1, p: 1, height: "auto"  }}>

                      <Typography sx={{ fontWeight: "bold", p: 1}}>Filters</Typography>
                      <div style={{display: "flex", justifyContent: "space-between", gap: "30px", paddingLeft: "20px", paddingRight: "20px"}}>
                        <div>
                          <div className="form-group">
                            <Field style={{width: "230px"}} name="id" type="text" className="form-control" component={FormResultIdTextField} />
                          </div>
                          <div className="form-group">
                            <Field name="user_id" type="text" className="form-control" component={FormUserIdTextField} />
                          </div>
                          <div className="form-group">
                          <Field 
                              name="test_type"
                              margin="normal"
                              className="form-control" 
                              value={formik.values.test_type}
                              onChange={formik.handleChange}
                              options={test_types.map(test_type => ({ value: test_type.name, label: test_type.name }))}
                              component={FormTestTypeSelectField} 
                              />
                          </div>
                          <div className="form-group">
                          <Field 
                              name="organisation_id" 
                              margin="normal"
                              className="form-control" 
                              value={formik.values.organisation_id}
                              onChange={formik.handleChange}
                              options={organisations.map(organisation => ({ value: organisation.id, label: organisation.name }))}
                              component={FormOrganisationSelectField} 
                              />
                          </div>
                          <div className="form-group">
                            <Field name="limit" type="text" className="form-control" component={FormLimitTextField} />
                          </div>
                        </div>
                        <div>
                          <div className="form-group">
                            <Field name="lot_number" type="text" className="form-control" component={FormLotNumberTextField} />
                          </div>
                          <div className="form-group">
                            <Field  style={{width: "230px"}} name="sample_id" type="text" className="form-control" component={FormSampleIDTextField} />
                          </div>
                          <div className="form-group">
                            <Field name="sample_type" type="text" className="form-control" component={FormSampleTypeTextField} />
                          </div>
                          <div className="form-group">
                            <Field name="visual_result" type="text" className="form-control" component={FormVisualResultTextField} />
                          </div>
                          <div className="form-group">
                            <Field name="skip" type="text" className="form-control" component={FormSkipTextField} />
                          </div>
                        </div>
                      </div>
                    </Card>

                    <Card sx={{ width: "100%", boxShadow: 10, paddingBottom: "20%", borderRadius: 1, p: 1, height: "auto"  }}>
                    <div style={{display: "flex", width: "100%", justifyContent: "space-between", gap: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom:"10px", paddingTop:"10px"}}>
                      <Button
                          className="form-group"
                          sx={{width: "150px"}}
                          onClick={(() => {handleResetValues(); setErrors(null)})}
                          variant="outlined"
                          >
                          Clear
                      </Button>  
                      <Divider orientation="vertical" variant="middle" flexItem /> 
                      <div style={{display: "flex", gap: "30px"}}>
                        <Button
                            className="form-group"
                            type="submit"
                            sx={{width: "230px"}}
                            variant="contained"
                            autoFocus
                            >
                            Search
                        </Button>  
                      </div>  
                    </div>
                    </Card>
                  </div>
                </Box>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </Card>               
  </form>
</Paper>
</FormikProvider>
</Box>
);
};

export default ResultsFilterBar;






