import { AppBar, Backdrop, Box, Grid, IconButton, Menu, MenuItem, SpeedDial, SpeedDialAction, SpeedDialIcon, styled, Tab, Tabs, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import assets from "../../assets";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation } from 'react-router-dom';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const pathToTabIndex: Record<string, number>  = {
  '/': 0,
  '/results': 1,
  '/feedback': 2,
  '/users': 3,
  '/profile': 4
};

const Topbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState<any>(pathToTabIndex[location.pathname] ?? 0);
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const authData = useSelector((state: RootState) => state.auth);

  const actions = [
    { icon: <LogoutIcon />, name: 'Logout' },
    { icon: <AccountBoxIcon />, name: 'Profile' },
    { icon: <Typography sx={{display: "flex", justifyContent:"baseline", alignItems: "center", width: "fit-contents", marginRight: "40px"}}>{authData?.user?.first_name}</Typography>, name: 'Me' },
  ];

  useEffect(() => {
    const newValue = pathToTabIndex[location.pathname];
    if (newValue !== undefined) {
      setValue(newValue);
    }
  }, [location.pathname]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate('/');
        break;
      case 1:
        navigate('/results');
        break;
      case 2:
        navigate('/feedback');
        break;
      case 3:
        navigate('/users');
        break;
      case 4:
        navigate('/profile');
        break;
      case 5:
        dispatch(logout());
        navigate('/login');
        break;
      default:
        navigate('/');
    }
  };

  return (
    <AppBar
    position="fixed"
    sx={{
      width: "100%",
      boxShadow: "unset",
      zIndex: (theme) => 50,
    }}
    >
    <Box>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={3}>
          <Box sx={{ paddingLeft: 3, display: 'flex', justifyContent: 'flex-start', alignItems: "center" }}>
            <Link to="/" onClick={() => setValue(0)}>
              <img src={assets.images.novarumLogo} alt="logo" style={{ height: 'auto', maxWidth: '80%' }} />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{display: "flex", justifyContent:"center"}}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="navigation tabs"
          >
            <Tab label="Dashboard" />
            <Tab label="Results" />
            <Tab label="Feedback" />
            {!authData.isClientUser && 
            <Tab label="Users"/>
            }
            </Tabs>
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: "flex-end", paddingRight: "3%"}}>
          <Backdrop open={open} />
            <SpeedDial
              ariaLabel="SpeedDial tooltip example"
              sx={{
                // position: 'absolute', 
                // bottom: 8, 
                // right: 16,
                '& .MuiSpeedDial-actions': {
                  '& .MuiSpeedDialAction-fab': {
                    margin: '20px',
                  }
                }
              }}      
              FabProps={{
                sx: {bgcolor: "#AB3B40",
                '&:hover': {
                  bgcolor: '#AB3B40',
                }
              }}}        
              icon={<AccountBoxIcon />}
              onClose={handleClose}
              onOpen={handleOpen}
              open={open}
              direction='left'

              
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipPlacement="left"
                  tooltipOpen
                  FabProps={{
                    sx: {
                      '&:hover': {
                        bgcolor: action.name === "Me" ? "transparent" : '#AB3B40',
                      },
                    },
                  }}
                  sx={{display: "flex", justifyContent: "space-evenly", gap: "30px"}}
                  onClick={() => {
                    if (action.name === 'Profile' || action.name === 'Me') {
                      navigate('/profile');
                      handleClose()
                    } else if (action.name === 'Logout') {
                      dispatch(logout());
                      navigate('/login');
                      handleClose()
                    }
                  }}
                />
              ))}
            </SpeedDial>
        </Grid>
      </Grid>
    </Box>
    </AppBar>
  );
};

export default Topbar;
