
interface EditUserCardProps {
    user: any
    setUser: (detail: any) => void;

  }

const EditUserCard: React.FC<EditUserCardProps> = ({user , setUser}) => {

    return ( 
        <>
        <h1>User Edit card</h1>
        <p>User id: {user.id}</p>
        <button onClick={() => setUser(null)}>Back</button>
        </>
     );
}
 
 
export default EditUserCard;