

import { Card, CardContent, LinearProgress, Grid, Box, Button, Link, makeStyles, Paper, Typography, FormControlLabel, Select, MenuItem, TextField, Checkbox, NativeSelect, OutlinedInput, FormControl, InputLabel, List, ListItem, Table, TableRow, TableCell } from '@mui/material';
import React, { Component, useEffect, useState } from 'react'
import { Field, Form, Formik, FormikProvider, useFormik } from 'formik';

import * as Yup from "yup";
//import PostService from "../../../services/post.service";
import { Navigate, Link as routerLink } from "react-router-dom";
import Divider from '@mui/material/Divider';
import { Title } from '@mui/icons-material';
import { TCRatioField, TextDetailField, FormVisualResultsField, ImageField, TScoreField, CScoreField, CPeakField, NotesField, TPeakField  } from '../fields/resultDetailFields';
import axios from 'axios';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material/Select';
import assets from '../../../assets'
import colorConfigs from '../../../configs/colorConfigs';
import ResultDetailGraph from '../graphs/ResultDetailGraph';
import ResultClassLabel from '../buttons/resultClassLabel';
import ResultConcLabel from '../buttons/resultConcLabel';
import { useTheme } from '@mui/material/styles';


interface ResultDetailProps {
  result: any;
}

interface LineData {
    score: number | string;
    peak_position: number | string;
    tc_ratio: number;
  }
  
  interface GraphData {
    test_lines: LineData[];
    cscore: number | string;
    cpeak: number | string;
    profile: string[];
    baseline: string[];
    quantitativeresult: number | string;
    thesholdmin: number | string;
    thesholdmax: number | string;
  }

axios.defaults.baseURL = window.env.API_URL;
// const access = localStorage.getItem("access")
const loginpage = "/auth/login"

const phoneRegEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ResultDetailCard: React.FC<ResultDetailProps> = ({result}) => {
    const [viewedResult, setViewedResult] = useState<any[]>([])
    const [graphData, setGraphData] = useState<GraphData>({
        test_lines: [],
        cscore: '',
        cpeak: '',
        profile: [''],
        baseline: [''],
        quantitativeresult: '',
        thesholdmin: '',
        thesholdmax: '', })
    const [colour, setColour] = useState<any>("")
    const [classification, setClassification] = useState<any>()
    const [conc, setConc] = useState<any>()
    const [testImage, setTestImage] = useState<any>()
    const [stripImages, setStripImages] = useState<any[]>([])
    const [sampleType, setSampleType] = useState<any>()
    const [lines, setLines] = useState<any[]>([])
    // const navigate = useNavigate();
    const location = useLocation();

    const validationSchema = () => {
        return Yup.object().shape({
        });
    }

    const theme = useTheme();


    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            app_build: '',
            app_version: '',
            device_model: '',
            device_uuid: '',
            full_image: '',
            id: '',
            latitude: '',
            longitude: '',
            lot_number: '',
            notes: '',
            organisation_id: '',
            os_version: '',
            sample_id: '',
            sample_type: '',

            strip_image_paths: [],
            // Test lines
            baseline: '',
            cline_score: '',
            cline_peak_position: '',
            // Lines
            lines: [],
            tline_1_score: '',
            tline_1_peak_position: '',
            tline_1_ratio: '',
            tline_2_score: '',
            tline_2_peak_position: '',
            tline_2_ratio: '',
            profile: '',

            test_type: '',
            timestamp: '',
            user_id: '',
            uuid: '',
            visual_result: '',
        
        },
        validationSchema,
        onSubmit: (values) => {
        },
    });

    useEffect(() => {
                console.log("Result Data", result)
                setViewedResult(result)
                setTestImage(result.full_image_path)
                // setTestImage("https://pub.mdpi-res.com/biosensors/biosensors-11-00211/article_deploy/html/images/biosensors-11-00211-g001.png?1627912606")

                console.log("SEAN", [result.full_image_path])
                setGraphData({
                    test_lines: result.test_strips[0].lines,
                    cscore: result.test_strips[0].c_line.score,
                    cpeak: result.test_strips[0].c_line.peak_position,
                    profile: result.test_strips[0].profile,
                    baseline: result.test_strips[0].baseline,
                    quantitativeresult: 555, // DO WE NEED THIS
                    thesholdmin: 30,  // DO WE NEED THIS
                    thesholdmax: 1000,  // DO WE NEED THIS
                })

                setSampleType(result.sample_type);
                formik.setFieldValue('app_build', result.app_build);
                formik.setFieldValue('app_version', result.app_version);
                formik.setFieldValue('device_model', result.device_model);
                formik.setFieldValue('device_uuid', result.device_uuid);
                formik.setFieldValue('full_image', result.full_image);
                formik.setFieldValue('id', result.id);
                formik.setFieldValue('latitude', result.latitude);
                formik.setFieldValue('longitude', result.longitude);
                formik.setFieldValue('lot_number', result.lot_number);
                formik.setFieldValue('notes', result.notes);
                formik.setFieldValue('organisation_id', result.organisation_id);
                formik.setFieldValue('os_version', result.os_version);
                formik.setFieldValue('sample_id', result.sample_id);
                formik.setFieldValue('sample_type', result.sample_type);

                // Setting strip_image_paths as an array to dynamically render these
                formik.setFieldValue('strip_image_paths', result.strip_image_paths);
                if (result.strip_image_paths) {
                    setStripImages([result.strip_image_paths])
                    // setStripImages(["https://pub.mdpi-res.com/biosensors/biosensors-11-00211/article_deploy/html/images/biosensors-11-00211-g001.png?1627912606","https://pub.mdpi-res.com/biosensors/biosensors-11-00211/article_deploy/html/images/biosensors-11-00211-g001.png?1627912606"])
                }

                // Test Strips
                formik.setFieldValue('cline_score', parseFloat(result.test_strips[0].c_line.score).toFixed(2));
                formik.setFieldValue('cline_peak_position', parseFloat(result.test_strips[0].c_line.peak_position).toFixed(2));
                
                // Dynaically sets tline score, peak and tc_ratio
                result.test_strips[0].lines.map((line:any, index:any, array:any[]) => {
                    formik.setFieldValue(`tline_${array.length - index}_score`, parseFloat(line.score).toFixed(2));
                    formik.setFieldValue(`tline_${array.length - index}_peak_position`, parseFloat(line.peak_position).toFixed(2));
                    formik.setFieldValue(`tline_${array.length - index}_ratio`, parseFloat(line.tc_ratio).toFixed(2));
                })

                // Setting lines as an array to dynamically render these
                formik.setFieldValue('lines', result.test_strips[0].lines);
                setLines(result.test_strips[0].lines)
                

                formik.setFieldValue('test_type', result.test_type)
                formik.setFieldValue('timestamp', dayjs(result.timestamp).format('YYYY-MM-DD HH:mm:ss'));
                formik.setFieldValue('user_id', result.user_id)
                formik.setFieldValue('uuid', result.uuid)
                formik.setFieldValue('visual_result', result.visual_result)
    
    }, []);

    return (
        <FormikProvider value={formik}>
            <Paper variant="outlined" sx={{width: '100%', overflow: 'auto', m: "auto", p: 2, }}>
                <Grid container spacing={3} wrap="nowrap" paddingTop="10px" alignItems="top" justifyContent="flex-start" style={{ minWidth: "100%" }}>
                    <Grid item xs={12} sm={6} md={3} style={{ display: "flex", flexDirection: "column", minWidth: "366px" }}>

                            <Card sx={{ p:2, height: "100%"}}>
                                <div className="form-group">
                                    <Field name="timestamp" type="text" className="form-control" label="Created At" component={TextDetailField}  />
                                    <Field name="user_id" type="text" className="form-control" label="Created By" component={TextDetailField} />
                                    <Field name="lot_number" type="text" className="form-control" label="Lot #" component={TextDetailField} />
                                    <Field name="sample_id" type="text" className="form-control" label="Sample ID" component={TextDetailField} />
                                    <Field name="sample_type" type="text" className="form-control" label="Sample Type" component={TextDetailField} />
                                    <Field name="test_type" type="text" className="form-control" label="Test Type" component={TextDetailField}  />
                                </div>
                            </Card>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ minWidth: "610px", display: "flex", flexDirection: "column",  }}>
                            <Card sx={{ p:2, height: "100%"}}>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <div style={{display: "flex", gap: "50px"}}>
                                        <Field name="nothing" visualResult={formik.values.visual_result} type="text" className="form-control" label="Visual Result" component={FormVisualResultsField} />
                                        <Field name="nothing" image={testImage} type="text" className="form-control" label="Test image" component={ImageField} />
                                </div>
                                <ResultDetailGraph graphdata = {graphData}/>
                            </div>
                            <Table sx={{ maxWidth: 800, align: "left" }} >
                            <TableRow>
                                    <TableCell>                                    
                                        <InputLabel
                                            sx={{
                                                paddingX: 1,
                                                paddingTop: 4,
                                                fontSize: 22,
                                                transform: 'translate(0, -1.5px) scale(0.75)', // Mimic shrunken transformation
                                                transformOrigin: 'top left',                                            
                                                fontWeight: 1000,  
                                            }}
                                        >
                                            Control line
                                        </InputLabel>
                                    </TableCell>                                    
                                    <TableCell><Field name="cline_score" type="text" className="form-control" component={CScoreField} /></TableCell>
                                    <TableCell><Field name="cline_peak_position" type="text" className="form-control" component={CPeakField} /></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            {lines.map((line, index, array) => {
                              return (
                                <TableRow key={index}>
                                    <TableCell>                                    
                                        <InputLabel
                                            sx={{
                                                paddingX: 1,
                                                paddingTop: 4,
                                                fontSize: 22,
                                                transform: 'translate(0, -1.5px) scale(0.75)', // Mimic shrunken transformation
                                                transformOrigin: 'top left',                                            
                                                fontWeight: 1000,                                          
                                            }}
                                        >
                                            Test line {array.length - index}
                                        </InputLabel>
                                    </TableCell>
                                    <TableCell><Field name={`tline_${array.length - index}_score`} type="text" className="form-control" component={TScoreField} /></TableCell>
                                    <TableCell><Field name={`tline_${array.length - index}_peak_position`} type="text" className="form-control" component={TPeakField} /></TableCell>
                                    <TableCell><Field name={`tline_${array.length - index}_ratio`} type="text" className="form-control" component={TCRatioField} /></TableCell>
                                </TableRow>      
                              )
                            })}
                            </Table>
                            </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} style={{ display: "flex", flexDirection: "column", minWidth: "366px" }}>
                            <Card sx={{ p:2, height: "100%"}}>

                            <div className="form-group">
                                {stripImages.map((image, index) => {
                                   const title = `Test strip image ${index +1}:`
                                   return (
                                    <Field key={index} name="nothing" image={image} type="text" className="form-control" label={title} component={ImageField} />
                                   )
                                })}
                                <Field name="notes" type="text" className="form-control" label="Notes" component={NotesField}  />
                                <Field name="app_version" type="text" className="form-control" label="App Version" component={TextDetailField} />
                                <Field name="app_build" type="text" className="form-control" label="App Build" component={TextDetailField} />
                                <Field name="os_version" type="text" className="form-control" label="OS Version" component={TextDetailField}  />
                            </div>
                            </Card>
                    </Grid>
                </Grid>
            </Paper>
        </FormikProvider>
    )
}

export default ResultDetailCard;