import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef } from 'material-react-table';
import dayjs from 'dayjs';
import { Box, Button, Grid, Link, ListItemIcon, Menu, MenuItem, MenuProps, Paper, alpha, styled } from '@mui/material';
import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CssBaseline from '@mui/material/CssBaseline';

const StyledMenu = styled((props: any) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


interface LastFiveFListProps {
    columns: any[];
    data: any[];
    isLoading: boolean;
  }


const LastFiveFList: React.FC<LastFiveFListProps> = ({
  columns,
  data,
  isLoading
}) => {
    const navigate = useNavigate()

    
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

      const handleExportRows = (rows: MRT_Row<any>[]) => {
        
    };

    return data.length > 0 ? ( 
        <MaterialReactTable
            columns={columns}
            data={data}
            state={{
              isLoading
            }}
            
            // onColumnFilterFnsChange

            muiPaginationProps={{
            rowsPerPageOptions: [50, 500, 1000],
            }}
            positionActionsColumn="last"
            enableRowSelection
            selectAllMode="all"
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid rgba(81, 81, 81, 1)',
                    backgroundColor: "#AB3B40",
                    color: "white"
                },
            }}
            muiTableBodyRowProps={({ row }) => ({
                onClick: (event) => {
                    console.info(event, row.original.id);
                    navigate(`/results/:${row.original.id}`, { state: { id: row.original.id } });
                },
                sx: {
                    cursor: 'pointer',
                },
            })}
            initialState={{ density: 'compact' }}
            renderTopToolbarCustomActions={({ table }) => {

                const handleArchiveResults = () => {
                    table.getSelectedRowModel().flatRows.map(row => {
                        //Add archive logic
                    });
                };

                const handleExportRows = (rows: MRT_Row<any>[]) => {
                    const excel_rows: string[] = [];
                    const now = dayjs().format('YYYY-MM-DD_HH-mm-ss');
                    const filename = `${now}_feedback.csv`;
                  
                    // Column headers
                    excel_rows.push("ID,User Id,Timestamp,Reader Feedback,Test Feedback,App Version,Device Model,Device uuid,Lot Number,Organisation ID,OS Version,Resuld uuid,Test Type");
                  
                    rows.forEach(row => {
                      const rowArray = [
                        row.original.id,
                        row.original.user_id,
                        row.original.timestamp ? dayjs(row.original.timestamp).format('YYYY-MM-DD HH:mm:ss') : "",
                        row.original.reader_feedback,
                        row.original.test_feedback,
                        row.original.app_version,
                        row.original.device_model,
                        row.original.device_uuid,
                        row.original.lot_number,
                        row.original.organisation_id,
                        row.original.os_version,
                        row.original.resuld_uuid,
                        row.original.test_type,
                      ].map(field => `"${field}"`);
                      excel_rows.push(rowArray.join(','));
                    });
                  
                    // Combine all rows into a single CSV string
                    const csvContent = excel_rows.join('\n');
                  
                    // Trigger download
                    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();

                    // Check if the link has a parent node before removing it
                    if (link.parentNode) {
                        link.parentNode.removeChild(link);
                    }
                  };
                  
                return (
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <Button
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                            sx={{ textTransform: 'none', fontSize: 16, background: '#AB3B40', color: "white" }}
                        >
                            Bulk actions
                        </Button>
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem disabled={
                                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                            } onClick={() => handleExportRows(table.getSelectedRowModel().rows)} disableRipple>
                                Export to Excel
                            </MenuItem>
                            {/* <MenuItem onClick={handleArchiveResults} disableRipple>
                                Archive Results
                            </MenuItem> */}
                        </StyledMenu>
                    </div>
                );
            }}

        />
     ) : (null)
}
 
export default LastFiveFList;
