import { Card, Box, Button, Paper, Typography, Alert, AlertTitle, Divider, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from 'formik';
import dayjs from 'dayjs';
import axios from 'axios';
import { fetchUserProfile, refreshAccessToken } from '../../../redux/features/auth/authSlice';
import {   
  FormDateToPicker,
  FormDateFromPicker,
  FormUserIdTextField,
  FormLotNumberTextField,
  FormTestTypeSelectField,
  FormAppliedFiltersTextField,
  FormFeedbackIdTextField,
  FormReaderFeedbackSelectField,
  FormTestFeedbackSelectField,
  FormDeviceModelTextField,
  FormDeviceUUIDTextField,
  FormOSVersionTextField,
  FormAppVersionTextField,
  FormAppBuildTextField,
  FormOrganisationSelectField,
  FormLimitTextField,
  FormSkipTextField, } from '../fields/FeedbackFilterBarFeilds';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useOrganisations } from '../CustomHooks';
import { FeedbackSessionStorageKey, FeedbackTableParameterStorageService } from '../../../services/sessionStorage';

interface FilterSettings {
    sorting: any[];
    columnFilters: any[];
    columnVisibility: { [key: string]: boolean };
    pagination: { pageSize: number; pageIndex: number };
  }

  
interface FilterBarProps {
  setData: (data: any[]) => void;
  setIsLoading: (data: boolean) => void;
  handleRemoveAllFilters: () => void;
  }



const FeedbackFilterBar: React.FC<FilterBarProps> = ({setData, setIsLoading, handleRemoveAllFilters   }) => {

    const authData = useSelector((state: RootState) => state.auth);
    const [errors, setErrors] = useState<string | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const [expanded, setExpanded] = useState<string | false>(false);
    const [saveCustomFilter, setSaveCustomFilter] = useState<boolean>(false);
    const errorRef = useRef<HTMLDivElement>(null);
    const searchRef = useRef<HTMLDivElement>(null);
    
    const validationSchema = () => {
        return Yup.object().shape({
          new_filter: Yup.string().max(15, 'Max 15 characters')
        });
    }

    useEffect(() => {
      if (errors && errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
  }, [errors]);
  
  
    const formik = useFormik({
      validateOnChange: false,
      validateOnBlur: false,
      initialValues: {
        id: "",
        test_feedback: "",
        reader_feedback: "",
        from_date: dayjs().format(),
        to_date: dayjs().format(),
        user_id: "",
        test_type: "",
        lot_number: "",
        device_model: "",
        device_uuid: "",
        result_uuid: "",
        os_version: "",
        app_version: "",
        app_build: "",
        new_filter: "",
        organisation_id: "",
        skip: "",
        limit: "",
        result_filters: [],
      },

      validationSchema,
      onSubmit: async (values) => {
        setErrors(null);
        setIsLoading(true)

        // Form handles getting results and patching users custom filters
        let updatedFilters;
        if (values.new_filter && authData.user) {
          const new_filter = {
            name: values.new_filter,
            filters: {
              id: values.id,
              test_feedback: values.test_feedback,
              reader_feedback: values.reader_feedback,
              from_date: values.from_date ? dayjs(values.from_date).format('YYYY-MM-DD') : undefined,
              to_date: values.to_date ? dayjs(values.to_date).format('YYYY-MM-DD') : undefined,
              user_id: values.user_id,
              test_type: values.test_type,
              lot_number: values.lot_number,
              device_model: values.device_model,
              device_uuid: values.device_uuid,
              result_uuid: values.result_uuid,
              organisation_id: values.organisation_id,
              os_version: values.os_version,
              app_version: values.app_version,
              app_build: values.app_build,
              skip: values.skip,
              limit: values.limit,
            }
          }
          updatedFilters = [new_filter, ...authData?.user?.feedback_filters]
          if (values.new_filter === "delete_all_custom_filters") {
            updatedFilters = []
          }
        }

        // Handle either updating users custom filters or queriying results
        const data = formik.values.new_filter ? {
            feedback_filters: updatedFilters
          } : {
            id: values.id,
            test_feedback: values.test_feedback,
            reader_feedback: values.reader_feedback,
            from_date: values.from_date ? dayjs(values.from_date).format('YYYY-MM-DD') : undefined,
            to_date: values.to_date ? dayjs(values.to_date).format('YYYY-MM-DD') : undefined,
            user_id: values.user_id,
            test_type: values.test_type,
            lot_number: values.lot_number,
            device_model: values.device_model,
            device_uuid: values.device_uuid,
            result_uuid: values.result_uuid,
            organisation_id: values.organisation_id,
            os_version: values.os_version,
            app_version: values.app_version,
            app_build: values.app_build,
            skip: values.skip,
            limit: values.limit,
          }
      
        // Concatinates search query parameters - Filter out empty values
        const queryParams = Object.entries(data).reduce((acc, [key, value]) => {
          if (value) { // This will ignore undefined values, effectively filtering out empty fields
            acc.set(key, value);
          }
          return acc;
        }, new URLSearchParams());
      
        // Generate the query string by concatinating any fields that have been entered
        const queryString = queryParams.toString();
      
        // Construct the URL
        let url = queryString ? `api/feedback/?${queryString}` : "api/feedback/";

        // Handles either patching users custom filters or fetching results
        const method = formik.values.new_filter ? 'patch' : 'get'
        console.log("url", url)

        // Handle updating users custom filters
        if (formik.values.new_filter) {
          url = `api/profile/`
        }

        try {
            await axios({
                method,
                url,
                data,
                headers: {
                    'Authorization': `Bearer ${authData.access}`
                }, 
            })
            .then(res => {
              handleRemoveAllFilters()
              setErrors(null);
              setData(res.data)
              setIsLoading(false)
              //set in local storage for persitance
              FeedbackTableParameterStorageService.set(FeedbackSessionStorageKey.data, res.data)
              console.log(res.data)
              console.log(`Feedback data successfully fetched.`)
              if (searchRef.current) {
                searchRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
              }
              if (method === 'get'){
                if (res.data.length < 1) {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  setErrors("There is no Feedback matching your search.");
                } else {
                  setExpanded(false)
                }
              } else {
                dispatch(fetchUserProfile());
                formik.setFieldValue('new_filter', "")
              }
              dispatch(refreshAccessToken());
            })

        } catch(error: any) {
            setIsLoading(false)
            dispatch(refreshAccessToken());
            if (error?.response?.status === 400) {
                const firstErrorItem = Object.keys(error.response.data)[0];
                setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
            } else {
                const errorText = method === 'patch' ? `Unknown error updated your filters` : `Unknown error fetching feedback data`;
                setErrors(errorText);
                console.error(errorText, error);
            }
        }
      },
  });

  // Currently hardcoding test types and feedback options
  const testTypes = [{name: "Phoenix"}]
  const feedbackOptions = [{tag: "good"}, {tag: "bad"}]
  const [organisations] = useOrganisations(authData)
  
  // Handles opening filter Accordian
  const handleOpeningAccordian =
  (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    setErrors(null)
  };  

  // Handles setting custom filters
  const handleCustomFilter = (feedback_filter: any) => {
    formik.setFieldValue('id', feedback_filter.filters.id || "");
    formik.setFieldValue('test_feedback', feedback_filter.filters.test_feedback || "");
    formik.setFieldValue('reader_feedback', feedback_filter.filters.reader_feedback || "");
    formik.setFieldValue('from_date', feedback_filter.filters.from_date || "");
    formik.setFieldValue('to_date', feedback_filter.filters.to_date || "");
    formik.setFieldValue('user_id', feedback_filter.filters.user_id || "");
    formik.setFieldValue('test_type', feedback_filter.filters.test_type || "");
    formik.setFieldValue('lot_number', feedback_filter.filters.lot_number || "");
    formik.setFieldValue('device_model', feedback_filter.filters.device_model || "");
    formik.setFieldValue('device_uuid', feedback_filter.filters.device_uuid || "");
    formik.setFieldValue('result_uuid', feedback_filter.filters.result_uuid || "");
    formik.setFieldValue('organisation_id', feedback_filter.filters.organisation_id || "");
    formik.setFieldValue('os_version', feedback_filter.filters.os_version || "");
    formik.setFieldValue('app_version', feedback_filter.filters.app_version || "");
    formik.setFieldValue('app_build', feedback_filter.filters.app_build || "");
    formik.setFieldValue('skip', feedback_filter.filters.skip || "");
    formik.setFieldValue('limit', feedback_filter.filters.limit || "");
  }

  // Handles setting custom filter buttons CSS
  const doFilterValuesMatch = (formikValues: any, filter: any) => {
    if (!filter) return false;
    const keysToCheck = Object.keys(filter);
    return keysToCheck.every(key => formikValues[key] === filter[key]);
  }

  // Handles the Clear button resetting filters
  const handleResetValues = () => {
    formik.setFieldValue('id', "");
    formik.setFieldValue('test_feedback', "");
    formik.setFieldValue('reader_feedback', "");
    formik.setFieldValue('from_date', "");
    formik.setFieldValue('to_date', "");
    formik.setFieldValue('user_id', "");
    formik.setFieldValue('test_type', "");
    formik.setFieldValue('lot_number', "");
    formik.setFieldValue('device_model', "");
    formik.setFieldValue('device_uuid', "");
    formik.setFieldValue('result_uuid', "");
    formik.setFieldValue('organisation_id', "");
    formik.setFieldValue('os_version', "");
    formik.setFieldValue('app_version', "");
    formik.setFieldValue('app_build', "");
    formik.setFieldValue('new_filter', "");
    formik.setFieldValue('skip', "");
    formik.setFieldValue('limit', "");
  }

  // Handles deleting all users custom filters
  const handleDeleteCustomFilters = () => {
    formik.setFieldValue('new_filter', "delete_all_custom_filters")
    formik.submitForm();
  }


  const handleCustomFilterSave = () => {
    setSaveCustomFilter(false)
    formik.submitForm();
  }

return (
  <Box ref={searchRef} sx={{ p: 1, marginX: "auto" }}>
    <FormikProvider value={formik}>
    <Paper variant="outlined" sx={{width: expanded ? '100%' : "40%", overflow: 'auto', m: "auto", p: 2, transition: "width 0.3s ease-in-out" }}>
        <form onSubmit={formik.handleSubmit}>
        <Card sx={{display:'flex', flexDirection: "column", width: "100%", justifyContent: 'space-between', boxShadow: 10, paddingBottom:"10%", borderRadius: 1, p: expanded ? 2 : 0, paddingTop: 0, overflowY: "auto", transition: "padding 0.5s ease-in-out"}} >
          {errors && (
            <Alert ref={errorRef} severity="error" style={{ marginTop: '16px' }}>
              <AlertTitle>Error</AlertTitle>
              {errors}
            </Alert>
          )}
            <div>
              <div>
                <Accordion expanded={expanded === 'panel1'} onChange={handleOpeningAccordian('panel1')}>
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 1, m: 0, ml: expanded ? "5%": 0, gap: "20px", transition: "margin-left 0.3s ease-in-out" }}
                    >
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        fontWeight: "bold",
                        m: "auto",
                        mt: 0,
                        mb: 0,
                        background: "#AB3B40",
                        borderRadius: 1,
                        width: expanded ? "65%" : "100%",
                        padding: 1,
                        transition: "width 0.3s ease-in-out"
                      }}
                    >
                      Load Feedback
                    </Typography>                  
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      overflowY: 'auto',
                      p: 0,
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: 2
                    }}
                    >
                    <Box sx={{ p: 1, display: "flex", gap: "30px", height: "100%"}}>
                    <div style={{display: "flex", flexDirection: "column", gap: "30px", height: "auto", flexGrow: 1 }}>
                      <Card sx={{display:'flex', width: "100%", justifyContent: 'flex-start', boxShadow: 10, paddingBottom: "20%", borderRadius: 1, p: 1}}>

                      <div style={{display: "flex", flexDirection: "column"}}>
                      <Typography sx={{ fontWeight: "bold", p: 1}}>Date Range</Typography>
                        <div className="form-group" style={{display: "flex", alignItems: "baseline", paddingLeft: "20px"}}>
                          <Field name="from_date" type="date" className="form-control" component={FormDateFromPicker} />
                          <div className="form-group" style={{padding: "8px"}}>
                            <Button
                                onClick={(() => {formik.setFieldValue('from_date', null)})}
                                variant="outlined"
                                sx={{fontSize: "0.8rem", height: "17px", minWidth: "16px", p: 0.5, borderColor: "#AB3B40", color: "#AB3B40"}}
                                >
                                x
                            </Button>  
                          </div>
                        </div>
                        <div className="form-group" style={{display: "flex", alignItems: "baseline", paddingLeft: "20px", marginTop: "7px" }}>
                          <Field name="to_date" type="date" className="form-control" component={FormDateToPicker} />
                          <div className="form-group" style={{padding: "8px"}}>
                            <Button
                                onClick={(() => {formik.setFieldValue('to_date', null)})}
                                variant="outlined"
                                sx={{fontSize: "0.8rem", height: "17px", minWidth: "16px", p: 0.5, borderColor: "#AB3B40", color: "#AB3B40"}}
                                >
                                x
                            </Button>  
                          </div>
                        </div>
                      </div>
                      <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end", gap: "10px", paddingLeft: "20px", paddingRight: "20px", paddingTop: "48px", paddingBottom: "10px"}}>
                          <Button
                              className="form-group"
                              sx={{fontSize: "0.8rem",  width: "165px"}}
                              variant={(formik.values.from_date === dayjs().format() && formik.values.to_date === dayjs().format() ) ? "contained" : "outlined"}
                              onClick={() => {
                                formik.setFieldValue('from_date', dayjs().format());
                                formik.setFieldValue('to_date', dayjs().format());
                              }}
                              >
                              Today
                          </Button>   
                          <Button
                              className="form-group"
                              sx={{fontSize: "0.8rem",  width: "165px"}}
                              variant={(formik.values.from_date === dayjs().subtract(1, 'day').format() && formik.values.to_date === dayjs().subtract(1, 'day').format() ) ? "contained" : "outlined"}
                              onClick={() => {
                                formik.setFieldValue('from_date', dayjs().subtract(1, 'day').format());
                                formik.setFieldValue('to_date', dayjs().subtract(1, 'day').format());
                              }}
                              >
                              Yesterday
                          </Button>              
                          <Button
                              className="form-group"
                              sx={{fontSize: "0.8rem",  width: "165px"}}
                              variant={(formik.values.from_date === dayjs().subtract(1, 'week').format() && formik.values.to_date === dayjs().format() ) ? "contained" : "outlined"}
                              onClick={() => {
                                formik.setFieldValue('from_date', dayjs().subtract(1, 'week').format());
                                formik.setFieldValue('to_date', dayjs().format());
                              }}
                              >
                              Last 7 Days
                          </Button>  
                          <Button
                              className="form-group"
                              sx={{fontSize: "0.8rem",  width: "165px"}}
                              variant={(formik.values.from_date === dayjs().subtract(1, 'month').format() && formik.values.to_date === dayjs().format() ) ? "contained" : "outlined"}
                              onClick={() => {
                                formik.setFieldValue('from_date', dayjs().subtract(1, 'month').format());
                                formik.setFieldValue('to_date', dayjs().format());
                              }}
                              >
                              Month to Date
                          </Button>              
                          <Button
                              className="form-group"
                              sx={{fontSize: "0.8rem",  width: "165px"}}
                              variant={(formik.values.from_date === dayjs().subtract(3, 'month').format() && formik.values.to_date === dayjs().format() ) ? "contained" : "outlined"}
                              onClick={() => {
                                formik.setFieldValue('from_date', dayjs().subtract(3, 'month').format());
                                formik.setFieldValue('to_date', dayjs().format());
                              }}
                              >
                              Quarter to Date
                          </Button> 
                          <Button
                              className="form-group"
                              sx={{fontSize: "0.8rem",  width: "165px"}}
                              variant={(formik.values.from_date === dayjs().subtract(1, 'year').format() && formik.values.to_date === dayjs().format() ) ? "contained" : "outlined"}
                              onClick={() => {
                                formik.setFieldValue('from_date', dayjs().subtract(1, 'year').format());
                                formik.setFieldValue('to_date', dayjs().format());
                              }}
                              >
                              Year to Date
                          </Button> 
                          </div>


                      </Card>

                      <Card sx={{display: "flex", flexDirection: "column", justifyContent:"space-between",  width: "100%", boxShadow: 10, minHeight: "138px", borderRadius: 1, p:1, height: "auto" }} >   
                      <Typography sx={{ fontWeight: "bold", p: 1}}>Custom Filters</Typography>
                      <div style={{display: "flex", justifyContent: "space-between", paddingBottom: "10px"}}>

                      
                      <div style={{display: "flex", flexDirection: "column", paddingLeft: "20px", paddingRight: "20px", width: "50%"}}>
                      <div style={{display: "flex"}}>
                        {!saveCustomFilter ? 
                            <Button
                                className="form-group"
                                variant="outlined"
                                sx={{fontSize: "0.8rem", width: "200px", m: "7px 0"}}
                                onClick={() => {setSaveCustomFilter(true); formik.setFieldValue('new_filter', "")}}
                                >
                                Create Custom Filter
                            </Button>
                        : 
                            <Field name="new_filter" type="text" className="form-control" component={FormAppliedFiltersTextField} />
                          }   
                        </div>
                        {!saveCustomFilter && <Divider orientation="vertical" variant="middle" flexItem /> }


                   {!saveCustomFilter ? 
                      <Button
                          variant="outlined"
                          onClick={() => handleDeleteCustomFilters()}
                          type="button"
                          sx={{fontSize: "0.8rem", borderColor: "#AB3B40", color: "#AB3B40" }}
                          disabled={authData?.user?.feedback_filters?.length === 0}
                          >
                          Clear Custom Filters
                      </Button>  
                   :
                   <div style={{display: "flex", justifyContent: "space-between", marginTop: "10px"}}>
                       <Button
                         onClick={() => {setSaveCustomFilter(false); formik.setFieldValue('new_filter', "")} }
                         variant="outlined"
                         sx={{fontSize: "0.8rem"}}
                         >
                         Cancel
                       </Button> 
                       <Divider orientation="vertical" variant="middle" flexItem /> 
                       <div style={{display: "flex", gap: "30px"}}>
                         <Button
                             className="form-group"
                             variant="contained"
                             type="button"
                             sx={{fontSize: "0.8rem"}}
                             onClick={() => handleCustomFilterSave()}
                             disabled={!formik.values.new_filter}
                             autoFocus={!formik.values.new_filter}
                             >
                             Save
                         </Button> 
                       </div>  
                       </div>
                     } 
                    </div>
                    <div>   
                          {authData.user?.feedback_filters?.length === 0 ? null :
                          <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end", gap: "10px", paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "10px"}}>
                          {authData.user?.feedback_filters?.map((feedback_filter) => {
                            const isMatch = doFilterValuesMatch(formik.values, feedback_filter.filters);
                            return (
                              <Button
                                  variant={isMatch ? "contained" : "outlined"}
                                  onClick={() => {handleCustomFilter(feedback_filter)}}
                                  sx={{fontSize: "0.8rem",  width: "165px"}}
                                  >
                                  {feedback_filter.name}
                              </Button> 
                            ) 
                          })}     
                          </div> 
                          }
                          </div>  
                    </div>
                   </Card>

                   </div>

            

                   <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between",height: "auto", flexGrow: 1 }}>

                   <Card sx={{ display: 'flex', flexDirection: "column", justifyContent: 'flex-start', width: "100%", boxShadow: 10, paddingBottom: "20%", borderRadius: 1, p: 1, height: "auto"  }}>

                   <Typography sx={{ fontWeight: "bold", p: 1}}>Filters</Typography>
                      <div style={{display: "flex", justifyContent: "space-between", gap: "30px", paddingLeft: "20px", paddingRight: "20px"}}>
                        <div>
                          <div className="form-group">
                            <Field style={{width: "230px"}} name="id" type="text" className="form-control" component={FormFeedbackIdTextField} />
                          </div>
                          <div className="form-group">
                            <Field name="user_id" type="text" className="form-control" component={FormUserIdTextField} />
                          </div>
                          <div className="form-group">
                          <Field 
                              name="test_feedback" 
                              margin="normal"
                              className="form-control" 
                              value={formik.values.test_feedback}
                              onChange={formik.handleChange}
                              options={feedbackOptions.map(feedback => ({ value: feedback.tag, label: feedback.tag }))}
                              component={FormTestFeedbackSelectField} 
                              />
                          </div>
                          <div className="form-group">
                          <Field 
                              name="reader_feedback" 
                              margin="normal"
                              className="form-control" 
                              value={formik.values.reader_feedback}
                              onChange={formik.handleChange}
                              options={feedbackOptions.map(feedback => ({ value: feedback.tag, label: feedback.tag }))}
                              component={FormReaderFeedbackSelectField} 
                              />
                          </div>
                          <div className="form-group">
                          <Field 
                              name="test_type" 
                              margin="normal"
                              className="form-control" 
                              value={formik.values.test_type}
                              onChange={formik.handleChange}
                              options={testTypes.map(testType => ({ value: testType.name, label: testType.name }))}
                              component={FormTestTypeSelectField} 
                              />
                          </div>
                          <div className="form-group">
                          <Field 
                              name="organisation_id" 
                              margin="normal"
                              className="form-control" 
                              value={formik.values.organisation_id}
                              onChange={formik.handleChange}
                              options={organisations.map(organisation => ({ value: organisation.id, label: organisation.name }))}
                              component={FormOrganisationSelectField} 
                              />
                          </div>
                          <div className="form-group">
                            <Field name="limit" type="text" className="form-control" component={FormLimitTextField} />
                          </div>
                        </div>
                        <div>
                          <div className="form-group">
                            <Field name="lot_number" type="text" className="form-control" component={FormLotNumberTextField} />
                          </div>
                          <div className="form-group">
                            <Field  style={{width: "230px"}} name="device_model" type="text" className="form-control" component={FormDeviceModelTextField} />
                          </div>
                          <div className="form-group">
                            <Field name="device_uuid" type="text" className="form-control" component={FormDeviceUUIDTextField} />
                          </div>
                          <div className="form-group">
                            <Field name="os_version" type="text" className="form-control" component={FormOSVersionTextField} />
                          </div>
                          <div className="form-group">
                            <Field name="app_version" type="text" className="form-control" component={FormAppVersionTextField} />
                          </div>
                          <div className="form-group">
                            <Field name="app_build" type="text" className="form-control" component={FormAppBuildTextField} />
                          </div>
                          <div className="form-group">
                            <Field name="skip" type="text" className="form-control" component={FormSkipTextField} />
                          </div>
                        </div>
                      </div>
                    </Card>

                    <Card sx={{ width: "100%", boxShadow: 10, paddingBottom: "20%", borderRadius: 1, p: 1, height: "auto", marginTop: "30px" }}>
                    <div style={{display: "flex", width: "100%", justifyContent: "space-between", gap: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom:"10px", paddingTop:"10px"}}>
                      <Button
                          className="form-group"
                          sx={{width: "150px"}}
                          onClick={(() => {handleResetValues(); setErrors(null)})}
                          variant="outlined"
                          >
                          Clear
                      </Button>  
                      <Divider orientation="vertical" variant="middle" flexItem /> 
                      <div style={{display: "flex", gap: "30px"}}>
                        <Button
                            className="form-group"
                            type="submit"
                            sx={{width: "230px"}}
                            variant="contained"
                            autoFocus
                            >
                            Search
                        </Button>  
                      </div>  
                    </div>
                    </Card>
                  </div>
                  </Box>
                <div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </Card>               
  </form>
</Paper>
</FormikProvider>
</Box>
);
};

export default FeedbackFilterBar;