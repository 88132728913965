import React from "react";
import HomePage from "../pages/home/HomePage";
import ResultPage from "../pages/results/ResultsPage";
import { RouteType } from "./config";
import FeedbackPage from "../pages/feedback/FeedbackPage";
import ProfilePage from "../pages/profile/ProfilePage";
import UsersPage from "../pages/users/UsersPage";

const appRoutes: RouteType[] = [
    {
        index: true,
        element: <HomePage />,
        state: "home"
    },
    {
        path: "/results",
        element: <ResultPage />,
        state: "results",
    },
    {
        path: "/feedback",
        element: <FeedbackPage />,
        state: "feedback",
    },
    {
        path: "/users",
        element: <UsersPage />,
        state: "users",
    },
    {
        path: "/profile",
        element: <ProfilePage />,
        state: "profile",
    },
]

export default appRoutes;