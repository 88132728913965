import { Box, Button, Grid, Link, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { isLoggedIn, refreshToken } from '../../redux/features/auth/authService';
import ResultsList from '../../components/common/tables/ResultsList';
import SignInDialog from '../../components/common/SignInDialog';
import axios from 'axios';
import ResultsFilterBar from '../../components/common/cards/ResultsFilterBar';
import { organisationColumns } from '../../components/common/tables/Columns';
import { refreshAccessToken } from '../../redux/features/auth/authSlice';
import { UserColumns } from '../../components/common/tables/UserColumns';
import UsersList from '../../components/common/tables/UsersList';
import EditUserCard from '../../components/common/cards/EditUserCard';
import UsersFilterBar from '../../components/common/cards/UsersFilterBar';
import { UserSessionStorageKey, UserTableParameterStorageService } from '../../services/sessionStorage';

const loginpage = "/login"

axios.defaults.baseURL = window.env.API_URL;


type Props = {}

type OrganisationKey = '99' | '1' | '2' | '3'; // Organisation ID

const UsersPage = (props: Props) => {
  
const navigate = useNavigate()
const authData = useSelector((state: RootState) => state.auth);


const [columns, setColumns] = useState(UserColumns["99"]);
const [data, setData] = useState<any[]>([{"2": 1}]);
const [isLoading, setIsLoading] = useState(false);
const [user, setUser] = useState<any>(null);
const [savedFilterSets, setSavedFilterSets] = useState<any[]>([]);

const [sorting, setSorting] = useState<any>(() => {
  return UserTableParameterStorageService.get(UserSessionStorageKey.sorting) || []
});

const [columnVisibility, setColumnVisibility] = useState<any>(() => {
  return UserTableParameterStorageService.get(UserSessionStorageKey.columnVisibility) || {}
});

const [columnFilters, setColumnFilters] = useState<any>(() => {
  return UserTableParameterStorageService.get(UserSessionStorageKey.columnFilters) || []
});

const [globalFilter, setGlobalFilter] = useState<string>(() => {
  return UserTableParameterStorageService.get(UserSessionStorageKey.search) || "";
});

const [pagination, setPagination] = useState<any>(() => {
  return UserTableParameterStorageService.get(UserSessionStorageKey.pagination) || { pageSize: 1000, pageIndex: 0 }
});

const [showGlobalFilter, setShowGlobalFilter] = useState<any>(() => {
  return UserTableParameterStorageService.get(UserSessionStorageKey.showSearch) || false
});

const handleRemoveAllFilters = () => {
  setGlobalFilter("")
  setShowGlobalFilter(false)
  setColumnFilters([])
  setColumnVisibility({})
  setPagination({ pageSize: 1000, pageIndex: 0 })
  setSorting([])
}

// AUTHENTICATION CHECK
const dispatch = useDispatch<AppDispatch>();
useEffect(() => {
  if (!authData.isAuthenticated) {
      navigate(loginpage);
      return;
  }
  if (!isLoggedIn(authData.access)) {
      dispatch(refreshAccessToken());
  }
}, [dispatch, navigate, authData.isAuthenticated]);


  useEffect(() => {
    setIsLoading(true);
    if (authData.isAuthenticated == false) {
      navigate("login")
    }
    
    axios.get(`api/users/`, { 
        headers: {
        'Authorization': `Bearer ${authData.access}`
      }
    })
      .then(res => {
        // Set columns to the organisation columns
        const usersOrganisation = authData.organisation as OrganisationKey;
        if (usersOrganisation) {
          const columns = UserColumns[usersOrganisation];
          if (columns) {
            setColumns(columns);
        }}
        // Set Data
        setData(res.data.results);
        setIsLoading(false);
        console.log(res.data.results)
      })
      .catch(err => {
        console.log(err)
      })
}, [authData]);



const handleSaveFilters = (newFilterSet: any) => {
  const updatedSavedFilterSets = [...savedFilterSets, newFilterSet];
  setSavedFilterSets(updatedSavedFilterSets);
};

  return authData.isAuthenticated == false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
    ) : authData.isAuthenticated && !authData.isClientUser ? (
  <div style={{ height: "100%", overflowX: "auto"}}>
    <div style={{ height: '100%', display: "flex", alignItems: "flex-start", paddingBottom: "2%" }}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
          style={{ height: '100%' }}
        >
          <Grid item xs={12}>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
              {/* <UsersFilterBar  setData={setData} onSave={handleSaveFilters} sorting={sorting} columnFilters={columnFilters} columnVisibility={columnVisibility} pagination={pagination} /> */}
              {user && <EditUserCard user={user} setUser={setUser}/>}
              {!user && (
                <Paper variant="outlined" sx={{ width: '95vw', overflow: 'auto', mx: "auto", padding: 2, boxShadow: 2, mt: 5, ml: 3 }}>
                <UsersList
                     columns={columns}
                     data={data}
                     isLoading={isLoading}
                     sorting={sorting}
                     columnVisibility={columnVisibility}
                     columnFilters={columnFilters}
                     pagination={pagination}
                     globalFilter={globalFilter}
                     showGlobalFilter= {showGlobalFilter}
                     setShowGlobalFilter={setShowGlobalFilter}
                     handleRemoveAllFilters={handleRemoveAllFilters}
                     setGlobalFilter={setGlobalFilter}
                     setSorting={setSorting}
                     setColumnVisibility={setColumnVisibility}
                     setColumnFilters={setColumnFilters}
                     setPagination={setPagination}
                     setUser={setUser}
                  />
                </Paper>
              )}            
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
) : (null);
};

export default UsersPage;

