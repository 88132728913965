import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef, type MRT_SortingState } from 'material-react-table';
import dayjs from 'dayjs';
import { Box, Button, Grid, Link, ListItemIcon, Menu, MenuItem, MenuProps, Paper, alpha, styled } from '@mui/material';
import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CssBaseline from '@mui/material/CssBaseline';
import { ResultSessionStorageKey, ResultSessionStorageModel, ResultTableParameterStorageService } from "../../../services/sessionStorage";

const StyledMenu = styled((props: any) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


interface ResultsListProps {
    columns: any[];
    data: any[];
    isLoading: boolean;
    sorting: any;
    columnVisibility: any;
    columnFilters: any;
    pagination: any;
    globalFilter: any;
    showGlobalFilter: any;
    setShowGlobalFilter: (value: any) => void;
    handleRemoveAllFilters: () => void;
    setGlobalFilter: (value: string) => void;
    setSorting: (sorting: any[]) => void;
    setColumnVisibility: (columnVisibility: { [key: string]: boolean }) => void;
    setColumnFilters: (filters: any[]) => void;
    setPagination: (pagination: { pageSize: number; pageIndex: number }) => void;
    setResult: (detail: any) => void;
  }

const ResultsList: React.FC<ResultsListProps> = ({
  columns,
  data,
  isLoading,
  sorting,
  columnVisibility,
  columnFilters,
  pagination,
  globalFilter,
  showGlobalFilter,
  setShowGlobalFilter,
  setGlobalFilter,
  handleRemoveAllFilters,
  setSorting,
  setColumnVisibility,
  setColumnFilters,
  setPagination,
  setResult
}) => {
    const navigate = useNavigate()

    
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSaveFilterClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

      const handleExportRows = (rows: MRT_Row<any>[]) => {
        
    };

    // Define the type for the keys for handling persistance of table settings in session storage
    type ResultSessionStorageKeyType = keyof ResultSessionStorageModel;

    // Generic handler function for persisting table parameters
    const handleParameterChange = <T,>(
        key: ResultSessionStorageKeyType,
        currentValue: T,
        setValueFunction: (value: T) => void,
        newValue: T | ((prevValue: T) => T)
        ) => {
        const updatedValue = typeof newValue === 'function' ? (newValue as (prevValue: T) => T)(currentValue) : newValue;
        ResultTableParameterStorageService.set(key, updatedValue);
        setValueFunction(updatedValue);
        };

    // Handlers for persisting table: sorting, pagination, visibility, filter, search, and search bar visibility
    const setSortingFunction = (newSortingValue: any) =>
        handleParameterChange(ResultSessionStorageKey.sorting, sorting, setSorting, newSortingValue);
    const setPaginationChangeFunction = (newPaginationValue: any) =>
        handleParameterChange(ResultSessionStorageKey.pagination, pagination, setPagination, newPaginationValue);
    const setColumnVisibilityFunction = (newColumnVisibilityValue: any) =>
        handleParameterChange(ResultSessionStorageKey.columnVisibility, columnVisibility, setColumnVisibility, newColumnVisibilityValue);
    const setFilterChangeFunction = (newFilterChangeValue: any) =>
        handleParameterChange(ResultSessionStorageKey.columnFilters, columnFilters, setColumnFilters, newFilterChangeValue);
    const setGlobalFilterChangeFunction = (newGlobalFilterChangeValue: string | ((prevValue: string) => string)) =>
        handleParameterChange(ResultSessionStorageKey.search, globalFilter, setGlobalFilter, newGlobalFilterChangeValue);
    const setShowGlobalFilterChangeFunction = (newShowGlobalFilterChangeValue: any) =>
        handleParameterChange(ResultSessionStorageKey.showSearch, showGlobalFilter, setShowGlobalFilter, newShowGlobalFilterChangeValue);



    return <MaterialReactTable
            columns={columns}
            data={data}
            state={{
              isLoading,
              sorting,
              columnVisibility,
              columnFilters,
              pagination,
              globalFilter,
              showGlobalFilter
            }}
            onSortingChange={setSortingFunction}
            onColumnVisibilityChange={setColumnVisibilityFunction}
            onColumnFiltersChange={setFilterChangeFunction}
            onPaginationChange={setPaginationChangeFunction}
            onGlobalFilterChange={setGlobalFilterChangeFunction}
            onShowGlobalFilterChange={setShowGlobalFilterChangeFunction}

            muiPaginationProps={{
            rowsPerPageOptions: [500, 1000],
            }}
            positionActionsColumn="last"
            enableRowSelection
            selectAllMode="all"
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid rgba(81, 81, 81, 1)',
                    backgroundColor: "#AB3B40",
                    color: "white"
                },
            }}
            muiTableBodyRowProps={({ row }) => ({
                onClick: (event) => {
                    console.log(row.original);
                    setResult(row.original)
                },
                sx: {
                    cursor: 'pointer',
                },
            })}
            initialState={{
                sorting,
                columnVisibility,
                columnFilters,
                pagination,
                globalFilter,
                // pagination: {
                //     pageSize: 50,
                //     pageIndex: 0
                // },
                // sorting: [
                //   {
                //     id: 'id',
                //     desc: true,
                //   },
                // ],
                // columnVisibility: {
                //     sample_id: false,
                //   },
                // columnFilters: [
                //     {
                //         id: 'user_id',
                //         value: 1,
                //     },
                // ],
            }}

            renderTopToolbarCustomActions={({ table }) => {

                const handleArchiveResults = () => {
                    table.getSelectedRowModel().flatRows.map(row => {
                        //Add archive logic
                    });
                };

                const handleExportRows = (rows: MRT_Row<any>[]) => {
                    if (rows.length === 0) return; // Return early if there are no rows to process
                
                    const excel_rows: string[] = [];
                    const now = dayjs().format('YYYY-MM-DD_HH-mm-ss');
                    const filename = `${now}_results.csv`;
                
                    // Utility function to capitalize and remove underscores from headers
                    const formatHeader = (header:any) => {
                        return header
                            .split('_')
                            .map((word:any) => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(' ');
                    };


                    // Dynamically determine column headers based on the keys of the first row
                    const headers = Object.keys(rows[0].original);
                    const filteredHeaders = headers.filter((header:any) => header !== "test_strips" && header !== "strip_image_paths");
                
                    // Prepare test_strip headers
                    const testStripHeaders = rows[0].original.test_strips?.length ? Object.keys(rows[0].original.test_strips[0]) : [];
                    const filteredTestStripHeaders = testStripHeaders.filter(header => header !== "c_line" && header !== "lines");
                
                    // Prepare c_line headers
                    const cLineHeaders = rows[0].original.test_strips?.[0]?.c_line ? Object.keys(rows[0].original.test_strips[0].c_line) : [];
                
                    // Prepare test line headers dynamically
                    const testLineHeaders = rows[0].original.test_strips?.[0]?.lines?.[0] ? Object.keys(rows[0].original.test_strips[0].lines[0]) : [];
                    const maxTestLines = Math.max(...rows.map(row => row.original.test_strips[0].lines.length));
                    const dynamicTestLineHeaders = [];
                    for (let i = 0; i < maxTestLines; i++) {
                        dynamicTestLineHeaders.push(...testLineHeaders.map((header:any) => `"Testline ${i + 1} ${header}"`));
                    }
                
                    // Prepare strip_image_paths headers dynamically
                    const stripImageHeaders = rows[0].original.strip_image_paths?.map((_:any, index:any) => `"strip image ${index + 1} path"`) || [];
                
                    // Construct the final header string
                    const finalHeaderString = [
                        ...filteredHeaders.map(header => `"${formatHeader(header)}"`),
                        ...filteredTestStripHeaders.map(header => `"${formatHeader(header)}"`),
                        ...cLineHeaders.map(header => `"C Line ${(formatHeader(header))}"`),
                        ...dynamicTestLineHeaders.map(header => formatHeader(header.replace(/"/g, ''))),
                        ...stripImageHeaders.map((header:any) => `"${formatHeader(header.replace(/"/g, ''))}"`)
                    ].join(',');
                    excel_rows.push(finalHeaderString);
                
                    // Populate the CSV rows
                    rows.forEach(row => {
                        const rowArray = filteredHeaders.map(header => {
                            let cellValue = row.original[header];
                            if (header === "timestamp") {
                                cellValue = dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss');
                            }
                            const formattedValue = cellValue ? (typeof cellValue === 'string' ? cellValue.replace(/"/g, '""') : cellValue) : ["tc_ratio", "score", "peak_position"].includes(header) ? "0.0" : "";
                            return `"${formattedValue}"`;
                        });
                
                        const testStripRows = row.original.test_strips.map((test_strip:any) => {
                            // Add test_strip values
                            const testStripValues = filteredTestStripHeaders.map((header:any) => {
                                const value = test_strip[header];
                                return `"${value ? value.toString().replace(/"/g, '""') : ''}"`;
                            });
                
                            // Add c_line values
                            const cLineValues = cLineHeaders.map(header => {
                                const value = test_strip.c_line[header];
                                return `"${value ? value.toString().replace(/"/g, '""') : ''}"`;
                            });
                
                            // Add test line values dynamically
                            const testLineValues = [];
                            for (let i = 0; i < maxTestLines; i++) {
                                const line = test_strip.lines[i] || {};
                                testLineValues.push(...testLineHeaders.map(header => {
                                    const value = line[header];
                                    return `"${value ? value.toString().replace(/"/g, '""') : ''}"`;
                                }));
                            }
                
                            return [...testStripValues, ...cLineValues, ...testLineValues].join(',');
                        });
                
                        // Add strip_image_paths values dynamically
                        const stripImageValues = row.original.strip_image_paths.map((path:any) => {
                            const value = path;
                            return `"${value ? value.toString().replace(/"/g, '""') : ''}"`;
                        });
                
                        if (row.original.test_strips.length > 0) {
                            row.original.test_strips.forEach((_:any, index:any) => {
                                const combinedRowArray = [...rowArray, ...testStripRows[index].split(','), ...stripImageValues];
                                excel_rows.push(combinedRowArray.join(','));
                            });
                        } else {
                            const combinedRowArray = [...rowArray, ...Array(filteredTestStripHeaders.length + cLineHeaders.length + dynamicTestLineHeaders.length).fill('""'), ...stripImageValues];
                            excel_rows.push(combinedRowArray.join(','));
                        }
                    });
                
                    // Combine all rows into a single CSV string
                    const csvContent = excel_rows.join('\n');
                
                    // Trigger download
                    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                
                    // Clean up by removing the link after triggering the download
                    if (link.parentNode) {
                        link.parentNode.removeChild(link);
                    }
                };
                

                return (
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <Button
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                            sx={{ textTransform: 'none', fontSize: 16, background: '#AB3B40', color: "white", mr: "10px" }}
                        >
                            Bulk actions
                        </Button>
                        <Button
                            id="demo-customized-button1"
                            variant="contained"
                            disableElevation
                            onClick={() => handleRemoveAllFilters()}
                            sx={{ textTransform: 'none', fontSize: 16, background: '#AB3B40', color: "white" }}
                        >
                            Reset Filters
                        </Button>
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem disabled={
                                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                            } onClick={() => handleExportRows(table.getSelectedRowModel().rows)} disableRipple>
                                Export to CSV
                            </MenuItem>
                        </StyledMenu>
                    </div>
                );
            }}
        />
}
 
export default ResultsList;

