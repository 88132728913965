import { AppBar, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, Paper, Slide, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { isLoggedIn } from '../../redux/features/auth/authService';
import ResultsList from '../../components/common/tables/ResultsList';
import SignInDialog from '../../components/common/SignInDialog';
import axios from 'axios';
import ResultsFilterBar from '../../components/common/cards/ResultsFilterBar';
import { organisationColumns } from '../../components/common/tables/Columns';
import { logout, refreshAccessToken } from '../../redux/features/auth/authSlice';
import { ResultSessionStorageKey, ResultTableParameterStorageService } from '../../services/sessionStorage';
import { type MRT_SortingState } from 'material-react-table';
import ResultDetailCard from '../../components/common/cards/ResultDetailCard';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

const loginpage = "/login"

axios.defaults.baseURL = window.env.API_URL;



type Props = {}


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const ResultsPage = (props: Props) => {

const navigate = useNavigate()
const authData = useSelector((state: RootState) => state.auth);
const [columns, setColumns] = useState(organisationColumns["99"]);
const [isLoading, setIsLoading] = useState<boolean>(false);
const [result, setResult] = useState<any>(null);
const [savedFilterSets, setSavedFilterSets] = useState<any[]>([]);

const [sorting, setSorting] = useState<any>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.sorting) || []
});

const [columnVisibility, setColumnVisibility] = useState<any>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.columnVisibility) || {}
});

const [columnFilters, setColumnFilters] = useState<any>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.columnFilters) || []
});

const [globalFilter, setGlobalFilter] = useState<string>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.search) || "";
});

const [pagination, setPagination] = useState<any>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.pagination) || { pageSize: 1000, pageIndex: 0 }
});

const [showGlobalFilter, setShowGlobalFilter] = useState<any>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.showSearch) || false
});

const [data, setData] = useState<any>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.data) || []
});


const handleRemoveAllFilters = () => {
  setGlobalFilter("")
  setShowGlobalFilter(false)
  setColumnFilters([])
  setColumnVisibility({})
  setPagination({ pageSize: 1000, pageIndex: 0 })
  setSorting([])
}

// AUTHENTICATION CHECK
const dispatch = useDispatch<AppDispatch>();
useEffect(() => {
  if (!authData.isAuthenticated) {
      dispatch(logout());
      navigate('/login');
      return;
  }
  if (!isLoggedIn(authData.access)) {
      dispatch(refreshAccessToken());
  }
  dispatch(refreshAccessToken());
}, [dispatch, navigate, authData.isAuthenticated, authData.access]);


  return authData.isAuthenticated === false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
    ) : authData.isAuthenticated === true ? (
  <div style={{ height: "100%", overflowX: "auto"}}>
    <div style={{ height: '100%', display: "flex", alignItems: "flex-start", }}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
          style={{ height: '100%' }}
        >
          <Grid item xs={12}>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
              <ResultsFilterBar setData={setData} setIsLoading={setIsLoading} handleRemoveAllFilters={handleRemoveAllFilters}
 />
              {/* {result && <ResultDetailCard result={result} setResult={setResult}/>} */}
              {/* {!result && ( */}
                <Paper variant="outlined" sx={{ width: '95vw', overflow: 'auto', mx: "auto", padding: 2, boxShadow: 2, mt: 5, ml: 3, mT: "100px" }}>
                  <ResultsList
                    columns={columns}
                    data={data}
                    isLoading={isLoading}
                    sorting={sorting}
                    columnVisibility={columnVisibility}
                    columnFilters={columnFilters}
                    pagination={pagination}
                    globalFilter={globalFilter}
                    showGlobalFilter= {showGlobalFilter}
                    setShowGlobalFilter={setShowGlobalFilter}
                    handleRemoveAllFilters={handleRemoveAllFilters}
                    setGlobalFilter={setGlobalFilter}
                    setSorting={setSorting}
                    setColumnVisibility={setColumnVisibility}
                    setColumnFilters={setColumnFilters}
                    setPagination={setPagination}
                    setResult={setResult}
                  />
                </Paper>
              {/* )}             */}
            </Box>
          </Grid>
        </Grid>
      </div>
      <Dialog
            open={result}
            fullScreen
            onClose={() => setResult("")}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
            sx={{
              backgroundColor: "#121212",
              '& .MuiDialog-paper': {
                backgroundColor: "#121212",
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p:1 }}>
                {result &&
                <Typography
                      onClick={() => setResult("")}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        fontWeight: "bold",
                        m: "auto",
                        mt: 0,
                        mb: 0,
                        width: "40%",
                        background: "#AB3B40",
                        borderRadius: 1,
                        padding: 1,
                        transition: "width 0.3s ease-in-out"
                      }}
                    >
                      Result {result.id}
                    </Typography>  
                }
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setResult("")}
                  aria-label="close"
                  sx={{ position: 'absolute', right: 15 }}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent sx={{backgroundColor: "#121212"}}>
                {result && 
                <ResultDetailCard result={result}/>
                } 
            </DialogContent>
        </Dialog>
    </div>
    
) : (null);
};

export default ResultsPage;

