import React, { useEffect, useState } from 'react'
import { FieldProps, getIn } from 'formik'
import { Checkbox, InputAdornment, MenuItem, Select, TextField, TextFieldProps } from '@mui/material'
import assets from '../../../assets'
import ResultClassLabel from '../buttons/resultClassLabel';

interface BatchStateFieldProps {
    batchstate: any;
}

interface SampleTypeFieldProps {
    sampletype: any;
}

export const FormPatientIDField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Patient ID"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const FormResultIDField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Result ID"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const FormResultDateTimeField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Result Date/Time"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}


export const FormResultDateTimeReceivedField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Result Date/Time Received in Portal"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}


export const FormTestTypeField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Test Type"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const FormResultValueField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label=""
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
            }}
        />
    )
}

export const FormModerateThresholdField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Moderate Threshold"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const FormHighThresholdField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="High Threshold"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const FormLotCodeField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Lot Code"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const FormExpiryDateField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="ExpiryDate"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const FormTestLineHeightField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Test Line Height"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const FormControlLineHeightField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Control Line Height"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const FormTCRatioField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="T/C Ratio"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const FormResultClassificationField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Result Classification"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const FormDeviceManufacturerField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Manufacturer"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const FormDeviceModelField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Model"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const FormPhoneOSField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Phone OS"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const FormCalAppVersionField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="CalApp Version"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const FormReaderVersionField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Reader Version"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const MappingParameterAField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Mapping Parameter A"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const MappingParameterBField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Mapping Parameter B"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const IDField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="ID"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}

export const TScoreField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="TScore"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 16,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}
export const TPeakField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="TPeak"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 16,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}
export const TCRatioField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="TCRatio"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 16,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}
export const CScoreField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="CScore"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 16,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}
export const CPeakField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="CPeak"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,  
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 16,
                    fontWeight: 1000
                },
                shrink: true 
            }}
            
        />
    )
}
export const ProfileField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Profile"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const BaselineField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Baseline"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const stripimageField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="stripimage"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}

export const CreatedByField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Created By"
            margin="normal"
            variant="standard"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 20,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}

export const CreatedAtField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Created on"
            margin="normal"
            variant="standard"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 20,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}

export const OrgField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Org"
            margin="normal"
            variant="standard"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 16,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}

export const UnitField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Unit"
            margin="normal"
            variant="standard"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 20,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}

export const ReaderTypeField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Test name"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 20,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}
export const ReaderAnalysisTypeField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Reader Analysis Type"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}

export const DeviceMakeField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Device Make"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const DeviceModelField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Mobile phone"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 20,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}
export const DeviceOSField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Device OS"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const DeviceIDField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Device ID"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
// export const AppVersionField: React.FC<FieldProps & TextFieldProps> = props => {
//     const isTouched = getIn(props.form.touched, props.field.name)
//     const errorMessage = getIn(props.form.errors, props.field.name)

//     const { error, helperText, field, form, ...rest } = props

//     return (
//         <TextField
//             //   variant="outlined"
//             error={error ?? Boolean(isTouched && errorMessage)}
//             helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
//             {...rest}
//             {...field}
//             fullWidth
//             variant="standard"
//             label="App Version"
//             margin="normal"
//             autoFocus
//             InputProps={{
//                 readOnly: true,
//                 disableUnderline: true,
                
//             }}
//         />
//     )
// }
export const FirstOccGreenField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="First Occ. of Green"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const FirstOccYellowField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="First Occ. of Yellow"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const TransistionToResultField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Transistion To Result"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const AverageIterationField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="AverageIteration"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const LotNumberField: React.FC<FieldProps & TextFieldProps & BatchStateFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { batchstate, error, helperText, field, form, ...rest } = props

    return batchstate ? (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Lot number"
            margin="normal"
            autoFocus
            InputProps={{
                endAdornment: (
                    <InputAdornment 
                        position="end" 
                        sx={{colour:"#CC3366"}}>
                            {batchstate}
                    </InputAdornment>),
                readOnly: true,
                disableUnderline: true,
                
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 20,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    ) : (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Lot number"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 20,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}
export const TimestampField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Timestamp"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const SampleIDField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Sample ID"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 20,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}
export const IsPendingField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Is Pending"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const PatientIDField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Patient ID"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 20,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}
export const CorrectionTypeField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Correction Type"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const DeviceField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Device"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const CAField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="A"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const CBField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="B"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const CCField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="C"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const CDField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="D"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const BAField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="A"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const BBField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="B"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const BCField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="C"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const BDField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="D"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const InstructionTypeField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Instruction Type"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const SampleTypeField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Sample type"
            margin="normal"
            autoFocus

            InputProps={{
                readOnly: true,
                disableUnderline: true,
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 20,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}

export const TextDetailField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="outlined"
            margin="normal"
            autoFocus

            InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                    padding: '5px',
                    fontSize: 18,
                },
            }}
            sx={{
                fontSize: 18,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.2)', // Default border color
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.3)', // Same color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.3)', // Same color on focus
                  },
                  '& legend': {
                    width: 'auto', // Ensure the label doesn't get cut off
                  },
                },
              }}
            InputLabelProps={{
                style: {
                    fontSize: 22,
                    fontWeight: 1000,
                },
                shrink: true 
            }}
        />
    )
}
interface FormVisualResultsFieldProps extends FieldProps {
    visualResult: string;
  }

export const FormVisualResultsField: React.FC<FormVisualResultsFieldProps & TextFieldProps> = ({
    visualResult,
  ...props
}) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { error, helperText, field, form, ...rest } = props;
  return (
<TextField
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? (isTouched && errorMessage ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      margin="normal"
      autoFocus
      multiline
      rows={1}
      sx = {{
        fontSize: 18,
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.2)', // Default border color
            },
            '&:hover fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.3)', // Same color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.3)', // Same color on focus
            },
            '& legend': {
              width: 'auto', // Ensure the label doesn't get cut off
            },
          },
        }}
        InputLabelProps={{
            style: {
                fontSize: 22,
                fontWeight: 1000,
            },
            shrink: true 
        }}
      InputProps={{
        sx: {
            paddingY: 4,
            paddingX: 4,
            fontSize: 18,
        },
        readOnly: true,
        startAdornment: (
          <InputAdornment position="start" style={{ marginRight: 'auto', marginLeft: 'auto', height: '100%' }}>
            <ResultClassLabel labelcolour = "#AB3B40" value = {visualResult}/>
          </InputAdornment>
        ),
      }}
    />
  );
};

interface ImageFieldProps extends FieldProps {
    image: string;
  }

export const ImageField: React.FC<ImageFieldProps & TextFieldProps> = ({
    image,
  ...props
}) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { error, helperText, field, form, ...rest } = props;
  return (
<TextField
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? (isTouched && errorMessage ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      margin="normal"
      autoFocus
      multiline
      rows={1}
      sx = {{
        fontSize: 18,
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.2)', // Default border color
            },
            '&:hover fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.3)', // Same color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.3)', // Same color on focus
            },
            '& legend': {
              width: 'auto', // Ensure the label doesn't get cut off
            },
          },
        }}
        InputLabelProps={{
            style: {
                fontSize: 22,
                fontWeight: 1000,
            },
            shrink: true 
        }}
      InputProps={{
        sx: {
            paddingY: 4,
            paddingX: 12,
            fontSize: 18,
            alignItems: 'center', // Center vertically
        },
        readOnly: true,
        startAdornment: (
          <InputAdornment position="start" style={{ marginRight: 'auto', marginLeft: 'auto', height: '100%' }}>
             <img title="test" src={image} alt="test image" width="180px" height="60px" />
          </InputAdornment>
        ),
      }}
    />
  );
};


export const ScanTypeField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Scan Type"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const PatientNameField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Patient Name"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const YOBField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Year of Birth"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const SexField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Sex"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const BatchStateField: React.FC<FieldProps & TextFieldProps & BatchStateFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
    const { batchstate, error, helperText, field, form, ...rest } = props

    return batchstate ? (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label=""
            margin="normal"
            autoFocus
            InputProps={{
                endAdornment: (
                <InputAdornment 
                    position="end" 
                    sx={{colour:"#CC3366"}}>
                        {batchstate}
                </InputAdornment>),
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    ) : (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label=""
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
            sx={{ 
                input: { color: '#CC3366' } 
            }}
        />
    )
}
export const QuantitativeResultField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Quantitative Result"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const ThresholdMinField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Threshold Minimum"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}
export const ThresholdMaxField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="Threshold Maximum"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
        />
    )
}

export const NotesField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="outlined"
            label="Notes"
            margin="normal"
            autoFocus
            multiline
            rows={5}
            InputProps={{
                readOnly: true,
                disableUnderline: true, 
            }}
            sx = {{
                fontSize: 18,
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'rgba(255, 255, 255, 0.2)', // Default border color
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgba(255, 255, 255, 0.3)', // Same color on hover
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'rgba(255, 255, 255, 0.3)', // Same color on focus
                    },
                    '& legend': {
                      width: 'auto', // Ensure the label doesn't get cut off
                    },
                  },
            }}
            InputLabelProps={{
                style: {
                    fontSize: 20,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}



export const PositionField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Position"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const ScoreField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Score"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

export const CutoffField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            label="Cutoff"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
            }}
        />
    )
}

