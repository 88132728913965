import React from 'react';
import { FieldProps, getIn } from 'formik';
import { Checkbox, FormControlLabel, InputAdornment, MenuItem, Select, Switch, TextField, TextFieldProps } from '@mui/material';
import assets from '../../../assets';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


export const FormUserIDTextField: React.FC<FieldProps & TextFieldProps & { allowEdit: boolean }> = props => {
    const isTouched = getIn(props.form.touched, props.field.name);
    const errorMessage = getIn(props.form.errors, props.field.name);
  
    const { error, helperText, field, form, allowEdit, ...rest } = props;
    const readOnly = !allowEdit;

    return (
        <TextField
            // variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label="User ID"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: readOnly,
            }}
        />
    );
};

  export const FormUserFirstNameTextField: React.FC<FieldProps & TextFieldProps & { allowEdit: boolean }> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, allowEdit, ...rest } = props;
    const readOnly = !allowEdit;

    return (
      <TextField
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        InputLabelProps={{ shrink: true }}
        label="First Name"
        margin="normal"
        autoFocus
        InputProps={{
            readOnly: readOnly,
        }}
      />
    )
  }

  export const FormUserLastNameTextField: React.FC<FieldProps & TextFieldProps & { allowEdit: boolean }> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, allowEdit, ...rest } = props;
    const readOnly = !allowEdit;

    return (
      <TextField
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        InputLabelProps={{ shrink: true }}
        label="Last Name"
        margin="normal"
        autoFocus
        InputProps={{
            readOnly: readOnly,
        }}
      />
    )
  }

  export const FormUserEmailTextField: React.FC<FieldProps & TextFieldProps & { allowEdit: boolean }> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, allowEdit, ...rest } = props;
    const readOnly = !allowEdit;

    return (
      <TextField
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        InputLabelProps={{ shrink: true }}
        label="Email"
        margin="normal"
        autoFocus
        InputProps={{
            readOnly: readOnly,
        }}
      />
    )
  }

  export const FormUserTypeTextField: React.FC<FieldProps & TextFieldProps & { allowEdit: boolean }> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, allowEdit, ...rest } = props;
    const readOnly = !allowEdit;

    return (
      <TextField
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        InputLabelProps={{ shrink: true }}
        label="User Type"
        margin="normal"
        autoFocus
        InputProps={{
            readOnly: readOnly,
        }}
      />
    )
  }

  export const FormLastLoginTextField: React.FC<FieldProps & TextFieldProps & { allowEdit: boolean }> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, allowEdit, ...rest } = props;
    const readOnly = !allowEdit;

    return (
      <TextField
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        InputLabelProps={{ shrink: true }}
        label="Last Login"
        margin="normal"
        autoFocus
        InputProps={{
            readOnly: readOnly,
        }}
      />
    )
  }

    export const FormIsActiveSwitch: React.FC<FieldProps & { allowEdit: boolean }> = ({ field, form, allowEdit }) => {
        // Define the handleChange function
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            form.setFieldValue(field.name, event.target.checked);
        };

        // Determine if the switch should be disabled based on the allowEdit prop
        const disabled = !allowEdit;

        return (
            <FormControlLabel
                control={
                    <Switch
                        checked={Boolean(field.value)}
                        onChange={handleChange}
                        name={field.name}
                        color="primary"
                        disabled={disabled}
                    />
                }
                label="Is Active"
            />
        );
    };

    