import LoginLayout from '../../components/layout/LoginLayout';


type Props = {}

const LoginPage = (props: Props) => {
  return (
    <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
      <LoginLayout />
    </div>
  );
};

export default LoginPage;