import Signin from "../common/Signin";

const LoginLayout = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
        <Signin />
    </div>
  );
};

export default LoginLayout;