import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { Button, Card, Container, createTheme, Grid, IconButton, ThemeProvider, Toolbar } from '@mui/material';
import ProfileIcon from "../../assets/images/profile_icon.png"
import { useEffect, useState } from 'react';

interface ResultClassLabelProps {
    labelcolour: string,
    value: string,
}

const ResultClassLabel: React.FC<ResultClassLabelProps> = (props) => {
    const {labelcolour} = props;
    const {value} = props;
    const [labelcolourdata, setLabelColourData] = useState<any>()
    const [valuedata, setValueData] = useState<any>()

    useEffect(() => {
        const labelcolour_data = labelcolour
        const value_data = value
        setLabelColourData(labelcolour_data)
        setValueData(value_data)
      }, [value]);

    return (
        <Button
            disabled
            sx={{ mt: 1, 
                mb: 1, 
                ml: 8,
                p: 1,
                paddingX: 8,
                background: labelcolourdata,  
                color: "white", 
                textTransform: 'none', 
                fontSize: 16, 
                "&.Mui-disabled": {
                    background: labelcolourdata,
                    color: "white"
                } }}
        >
            {valuedata}
        </Button>
    );
}

export default ResultClassLabel;