import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getProfile, isLoggedIn } from '../../redux/features/auth/authService';
import { useParams } from 'react-router-dom';


interface UserProfile {
    id: number | undefined;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    is_superuser: boolean;
    is_staff: boolean;
    is_active: boolean;
    date_joined: string;
    last_login: string;
    user_type: string;
    organisation: number | undefined;
    result_filters: any[];
    feedback_filters: any[];
    user_filters: any[];
}

export interface AuthData {
    access: string | null;
    isAuthenticated: boolean;
    user: UserProfile | null;
    loading: boolean;
    error: string | null;
    isSuperUser: boolean;
    isNovarumUser: boolean;
    isClientUser: boolean;
    organisation: string | null;
}

export interface OrganisationData {
    id: number;
    name: string;
}



/**
 * Custom hook to fetch and manage clinic data based on the user type.
 * @param authData - The authData object containing authentication-related information.
 * @returns An array containing clinics.
 */
const useOrganisations = (authData: AuthData) => {
    // State to hold the fetched clinic data.
    const [organisations, setOrganisations] = useState<OrganisationData[]>([]);

    useEffect(() => {
        // Skip fetching if the user is not authenticated.
        if (!authData.isAuthenticated) {
            return;
        }

        // Asynchronous function for fetching clinic data.
        const fetchData = async () => {
            try {
                let organisationData: OrganisationData[] = [];
                if (authData.isSuperUser || authData.isNovarumUser) {
                    // Fetch Organisations using the 'api/organisations/' endpoint for administrators and support users.
                    const response = await axios.get('api/organisations/', {
                        headers: {
                            Authorization: `Bearer ${authData.access}`,
                        },
                    });

                    organisationData = (response.data.results as OrganisationData[]).map(
                        ({ id, name }) => ({
                            id,
                            name,
                        }),
                    );
                    // Update the component state with the fetched organisation data.
                    setOrganisations(organisationData);
                } else if (authData.isClientUser) {
                    // Fetch organisation data using the 'api/profile/' endpoint for other user types.
                    const response = await axios.get('api/profile/', {
                        headers: {
                            Authorization: `Bearer ${authData.access}`,
                        },
                    });

                    // Extract organisation data from the response, handling optional properties.
                    const { organisation, organisation_name  } = response.data.user;
                    organisationData = [
                        {
                            id: organisation || '',
                            name: organisation_name || '',
                        },
                    ];

                    // Update the component state with the fetched organisation data.
                    setOrganisations(organisationData);
                }
                console.log('-----Organisations------');
                console.log(organisationData);
            } catch (error) {
                // Log an error message if there is an issue fetching organisation data.
                console.error('Error fetching organisation data:', error);
            }
        };

        // Call the fetchData function to initiate the effect.
        fetchData();
    }, [authData.isAuthenticated, authData.isSuperUser, authData.isNovarumUser, authData.isClientUser]);

    return [organisations] as const;
};


export {
    useOrganisations
};
